<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="venues">VENUES</span>
        <span class="list-heading" *ngIf="!venues && !venue.VenueId">ADD VENUE</span>
        <span class="list-heading" *ngIf="!venues && venue.VenueId">EDIT VENUE</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="venues">
          <ngx-atlas-select
            (model)="venuePageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="venues && !venue.VenueId && (TraxPermission.VENUE_ADD | canAccess)"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Venue
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="venues">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="venues">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="venuesSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="venues">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive">
          <table class="table table-hover table-responsive" *ngIf="hasData()">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue Name
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueTypeName', columnSortOrder)">
                  Venue Type
                  <span *ngIf="selectedColumnName == 'VenueTypeName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueTypeName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('CorporationName', columnSortOrder)">
                  Corporation Name
                  <span *ngIf="selectedColumnName == 'CorporationName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'CorporationName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('SubscriptionType', columnSortOrder)">
                  Subscription Type
                  <span *ngIf="selectedColumnName == 'SubscriptionType' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'SubscriptionType' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('ExpiryDate', columnSortOrder)">
                  Expiry Date
                  <span *ngIf="selectedColumnName == 'ExpiryDate' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ExpiryDate' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let venue of venues
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getVenueById(venue.VenueId)">
                <td>{{ venue.VenueName }}</td>
                <td *ngIf="venue.VenueType != null">{{ venue.VenueType.VenueTypeName }}</td>
                <td *ngIf="venue.VenueType == null">-</td>
                <td>{{ venue.CorporationName }}</td>
                <td>{{ venue.Subscription.SubscriptionType }}</td>
                <td>{{ venue.Subscription.ExpiryDate | date: 'MM/dd/yyyy' }}</td>
                <td *ngIf="venue.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!venue.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!venues">
    <form #VenueForm="ngForm" class="form-horizontal" data-spy="scroll" data-target="#myScrollspy">
      <div class="venue-nav col-lg-2 col-md-2 col-sm-3 col-xs-12">
        <div class="nav-menu" (click)="scrollTo('BasicInfo')">
          <ng-container *ngIf="currentSection == 'BasicInfo'"><i class="menu-selected"></i></ng-container>
          Basic Information
        </div>

        <!-- Hide the Devops config on add venue -->
        <div *ngIf="!venues && venue.VenueId && isSysAdmin" class="nav-menu" (click)="scrollTo('DevOps')">
          <ng-container *ngIf="currentSection == 'DevOps'"><i class="menu-selected"></i></ng-container>
          DevOps Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('emergency')">
          <ng-container *ngIf="currentSection == 'emergency'"><i class="menu-selected"></i></ng-container>
          Emergency Contact
        </div>

        <div class="nav-menu" (click)="scrollTo('integration')">
          <ng-container *ngIf="currentSection == 'integration'"><i class="menu-selected"></i></ng-container>
          Integration
        </div>

        <div class="nav-menu" (click)="scrollTo('Authentication')">
          <ng-container *ngIf="currentSection == 'Authentication'"><i class="menu-selected"></i></ng-container>
          Authentication
        </div>

        <div class="nav-menu pb-40" (click)="scrollTo('GuestFeedbackConig')">
          <ng-container *ngIf="currentSection == 'GuestFeedbackConig'"><i class="menu-selected"></i></ng-container>
          Touchless guest feedback config
        </div>

        <div class="nav-menu pb-40" (click)="scrollTo('NegativeFeedbackConfig')">
          <ng-container *ngIf="currentSection == 'NegativeFeedbackConfig'"><i class="menu-selected"></i></ng-container>
          Negative Feedback Config
        </div>

        <div class="nav-menu" (click)="scrollTo('Maintenance')">
          <ng-container *ngIf="currentSection == 'Maintenance'"><i class="menu-selected"></i></ng-container>
          Cleaning Alert/Work Order Default Configuration &nbsp;&nbsp;&nbsp;&nbsp;
        </div>

        <div class="nav-menu" (click)="scrollTo('BeaconConfiguration')" style="margin-top: 20px">
          <ng-container *ngIf="currentSection == 'BeaconConfiguration'"><i class="menu-selected"></i></ng-container>
          Beacon Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('SensorConfiguration')">
          <ng-container *ngIf="currentSection == 'SensorConfiguration'"><i class="menu-selected"></i></ng-container>
          Sensor Configuration
        </div>

        <div class="nav-menu pb-40" (click)="scrollTo('UserAssignmentConfiguration')">
          <ng-container *ngIf="currentSection == 'UserAssignmentConfiguration'"
            ><i class="menu-selected"></i
          ></ng-container>
          User Assignment Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('GoalConfiguration')">
          <ng-container *ngIf="currentSection == 'GoalConfiguration'"><i class="menu-selected"></i></ng-container>
          Goals Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('PowerBIReportConfiguration')">
          <ng-container *ngIf="currentSection == 'PowerBIReportConfiguration'"
            ><i class="menu-selected"></i
          ></ng-container>
          Power BI Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('configurationReset')">
          <ng-container *ngIf="currentSection == 'configurationReset'"><i class="menu-selected"></i></ng-container>
          Configuration Reset
        </div>

        <div class="nav-menu" (click)="scrollTo('digitalDisplay')">
          <ng-container *ngIf="currentSection == 'digitalDisplay'"><i class="menu-selected"></i></ng-container>
          Digital Display
        </div>

        <div class="nav-menu" (click)="scrollTo('badgeconfiguration')">
          <ng-container *ngIf="currentSection == 'badgeconfiguration'"><i class="menu-selected"></i></ng-container>
          Badge Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('surveyconfiguration')">
          <ng-container *ngIf="currentSection == 'surveyconfiguration'"><i class="menu-selected"></i></ng-container>
          Survey Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('eventconfiguration')">
          <ng-container *ngIf="currentSection == 'eventconfiguration'"><i class="menu-selected"></i></ng-container>
          Event Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('gatesConfiguration')">
          <ng-container *ngIf="currentSection == 'gatesConfiguration'"><i class="menu-selected"></i></ng-container>
          Gates Configuration
        </div>

        <div class="nav-menu" (click)="scrollTo('FeatureConfiguration')">
          <ng-container *ngIf="currentSection == 'FeatureConfiguration'"><i class="menu-selected"></i></ng-container>
          Feature Configuration
        </div>
      </div>

      <div
        id="parentDiv"
        class="col-lg-10 col-md-10 col-sm-9 nav-main-content"
        scrollSpy
        [spiedTags]="['DIV']"
        (sectionChange)="onSectionChange($event)"
      >
        <div id="BasicInfo" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Basic Information</h3>
          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-3 col-md-3 col-sm-3">
              <div class="form-group imgBoxHeight">
                <div class="imgBox" style="background: none">
                  <button
                    class="btn-primary btn btn-xs button-text"
                    *ngIf="venueLogoSrc && isSysAdmin"
                    id="applicationLogoReset"
                    type="button"
                    (click)="logoFileReset()"
                  >
                    <i class="fa fa-times"></i> Remove
                  </button>
                  <label class="custom-file">
                    <input
                      type="file"
                      #fileInput
                      id="file"
                      name="venueLogo"
                      class="custom-file-input"
                      (change)="logoFileChange($event)"
                      style="display: none"
                      accept="image/*"
                      *ngIf="isSysAdmin"
                    />
                    <span class="custom-file-control"></span>
                    <img
                      id="preview"
                      [src]="venueLogoSrc != null ? venueLogoSrc : venueDefaultLogo"
                      class="application-logo"
                      [ngStyle]="{ cursor: isSysAdmin ? 'pointer' : 'default' }"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-9 form-group">
              <label for="VenueName"><span id="red-circle">&#x25CF;</span> Venue Name</label>
              <input
                id="VenueName"
                name="Venuename"
                #VenueName="ngModel"
                type="text"
                class="form-control"
                placeholder="Venue Name"
                [(ngModel)]="venue.VenueName"
                required
                maxlength="59"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div *ngIf="VenueName.errors && (VenueName.dirty || VenueName.touched)" class="alert alert-danger">
                <div [hidden]="!VenueName.errors.required" class="text-danger">Venue name is required.</div>
                <div [hidden]="!VenueName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Score"><span id="red-circle">&#x25CF;</span> Venue Type</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.VenueType.VenueTypeId = $event"
                  [list]="venueTypes"
                  [idField]="'VenueTypeId'"
                  [textField]="'VenueTypeName'"
                  [placeholder]="'Select Venue Type'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="venue.VenueType.VenueTypeId"
                >
                </ngx-atlas-select>
              </div>

              <!--<div class="col-lg-6 col-md-6 col-sm-6 form-group">
                                <label for="poweredBy">Powered By</label>
                                <div *ngIf="isSysAdmin">
                                    <input id="poweredBy" name="poweredBy" type="text" class="form-control" placeholder="Powered By" [(ngModel)]="venue.PoweredBy" maxlength="50" />
                                </div>

                                <div *ngIf="!isSysAdmin">
                                    <div class="clipping-wrapper">
                                        <p class="readOnly clipping">{{venue.PoweredBy}}</p>
                                    </div>
                                </div>
                            </div>-->
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="AgencyId">Agency Id</label>
              <div>
                <input
                  id="AgencyId"
                  name="Agencyid"
                  type="text"
                  class="form-control"
                  placeholder="Agency Id"
                  [(ngModel)]="venue.AgencyId"
                  maxlength="49"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="TimeZone"><span id="red-circle">&#x25CF;</span> Time Zone</label>
              <div>
                <ngx-atlas-select
                  (model)="selectedzone($event)"
                  [list]="timeZones"
                  [idField]="'DisplayName'"
                  [textField]="'DisplayName'"
                  [placeholder]="'Select Timezone'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="venue.Timezone"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="CountryName"><span id="red-circle">&#x25CF;</span> Corporation</label>
              <div>
                <ngx-atlas-select
                  (model)="changeCorporation($event, true)"
                  [list]="Corporations"
                  [idField]="'CorporationId'"
                  [textField]="'CorporationName'"
                  [placeholder]="'Select Corporation'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="venue.Corporation.CorporationId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="firstname"><span id="red-circle">&#x25CF;</span> Contact First Name</label>
              <div>
                <input
                  type="text"
                  autocomplete="invalid"
                  class="form-control"
                  name="Firstname"
                  placeholder="First Name"
                  [(ngModel)]="venue.FirstName"
                  required
                  #FirstName="ngModel"
                  maxlength="49"
                  [pattern]="utilitiesService.alphanumericRegex"
                />
                <div *ngIf="FirstName.errors && (FirstName.dirty || FirstName.touched)" class="alert alert-danger">
                  <div [hidden]="!FirstName.errors.required" class="text-danger">First name is required.</div>
                  <!--<div [hidden]="!FirstName.errors.pattern" class="text-danger">
                                        First name accepts only alphabets & single space between words.
                                    </div>-->
                  <div [hidden]="!FirstName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="lastname"><span id="red-circle">&#x25CF;</span> Contact Last Name</label>
              <div>
                <input
                  type="text"
                  autocomplete="invalid"
                  class="form-control"
                  name="Lastname"
                  placeholder="Last Name"
                  [(ngModel)]="venue.LastName"
                  required
                  #LastName="ngModel"
                  maxlength="49"
                  [pattern]="utilitiesService.alphanumericRegex"
                />
                <div *ngIf="LastName.errors && (LastName.dirty || LastName.touched)" class="alert alert-danger">
                  <div [hidden]="!LastName.errors.required" class="text-danger">Last name is required.</div>
                  <!--<div [hidden]="!LastName.errors.pattern" class="text-danger">
                                        Last name accepts only alphabets & single space between words.
                                    </div>-->
                  <div [hidden]="!LastName.errors.pattern" class="text-danger">
                    {{ utilitiesService.alphanumericRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="email"><span id="red-circle">&#x25CF;</span> Contact Email</label>
              <div>
                <input
                  type="email"
                  autocomplete="invalid"
                  class="form-control"
                  name="Email"
                  placeholder="Contact Email"
                  [(ngModel)]="venue.Email"
                  required
                  #Email="ngModel"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                  maxlength="319"
                />
                <div *ngIf="Email.errors && (Email.dirty || Email.touched)" class="alert alert-danger">
                  <div [hidden]="!Email.errors.required" class="text-danger">Email is required.</div>
                  <div [hidden]="!Email.errors.pattern" class="text-danger">Email is invalid.</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="contactNumber"><span id="red-circle">&#x25CF;</span> Mobile No.</label>
              <div>
                <input
                  type="text"
                  autocomplete="invalid"
                  class="form-control"
                  name="contactNumber"
                  placeholder="Mobile No. (123) 456-7890"
                  [(ngModel)]="venue.MobileNumber"
                  required
                  #contactNumber="ngModel"
                  minlength="10"
                  mask="(000) 000-0000"
                />
                <div
                  *ngIf="contactNumber.errors && (contactNumber.dirty || contactNumber.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!contactNumber.errors.required" class="text-danger">Mobile number is required.</div>
                  <div [hidden]="!contactNumber.errors.minlength" class="text-danger">
                    Mobile number should be 10 digit.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="addressLine1"><span id="red-circle">&#x25CF;</span> Address Line 1</label>
              <div>
                <input
                  type="text"
                  autocomplete="invalid"
                  class="form-control col-md-6"
                  name="AddressLine1"
                  placeholder="Address Line 1"
                  [(ngModel)]="venue.AddressLineOne"
                  required
                  #AddressLineOne="ngModel"
                  maxlength="249"
                />
                <!--pattern="^\S+$"-->
                <div
                  *ngIf="AddressLineOne.errors && (AddressLineOne.dirty || AddressLineOne.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!AddressLineOne.errors.required" class="text-danger">Address line 1 is required.</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="addressLine2">Address Line 2</label>
              <div>
                <input
                  type="text"
                  class="form-control"
                  name="AddressLine2"
                  placeholder="Address Line 2"
                  [(ngModel)]="venue.AddressLineTwo"
                  #addressLineTwo="ngModel"
                  maxlength="249"
                />
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="CountryName"> Country</label>
              <div>
                <ngx-atlas-select
                  (model)="countryChange($event)"
                  [list]="countries"
                  [idField]="'CountryId'"
                  [textField]="'CountryName'"
                  [placeholder]="'Select Country'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.Country.CountryId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="StateName"> State</label>
              <div>
                <ngx-atlas-select
                  (model)="stateChange($event)"
                  [list]="states"
                  [idField]="'StateId'"
                  [textField]="'StateName'"
                  [placeholder]="'Select State'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="stateDisabled"
                  [selectedValue]="venue.State.StateId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-section">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="CityName"> City</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.City.CityId = $event"
                  [list]="cities"
                  [idField]="'CityId'"
                  [textField]="'CityName'"
                  [placeholder]="'Select City'"
                  [allowClear]="true"
                  [multiple]="false"
                  [disabled]="cityDisabled"
                  [selectedValue]="venue.City.CityId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Pincode">Zip Code</label>
              <div>
                <input
                  type="text"
                  autocomplete="invalid"
                  class="form-control"
                  name="Zipcode"
                  placeholder="Zip Code"
                  [(ngModel)]="venue.ZipCode"
                  #Zipcode="ngModel"
                  pattern="[0-9]*$"
                  minlength="5"
                  maxlength="5"
                />
                <div *ngIf="Zipcode.errors && (Zipcode.dirty || Zipcode.touched)" class="alert alert-danger">
                  <!--<div [hidden]="!Zipcode.errors.required" class="text-danger">
                                        Zip code is required.
                                    </div>-->
                  <div [hidden]="!Zipcode.errors.pattern" class="text-danger">Zip code accepts only numeric.</div>
                  <div [hidden]="!Zipcode.errors.minlength || Zipcode.errors.pattern" class="text-danger">
                    Zip code should be 5 digit.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- This is a billable feature.  Eventually this needs to be under  -->
          <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isSysAdmin">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="airportCode"
                ><span id="red-circle" *ngIf="isFlightDataEnabled">&#x25CF;</span> Airport Code</label
              >
              <input
                type="text"
                pattern="[A-Z]{3}"
                id="airportCode"
                [(ngModel)]="venue.VenueIataCode"
                name="airportCode"
                class="form-control"
                placeholder="Airport Code"
                autocomplete="off"
                #airportCode="ngModel"
                [required]="isFlightDataEnabled"
              />
              <div *ngIf="airportCode.errors && (airportCode.dirty || airportCode.touched)" class="text-danger">
                <div *ngIf="airportCode.errors['required']">Airport code is required.</div>
                <div *ngIf="airportCode.errors['pattern']">Airport code must be exactly 3 uppercase characters.</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Hide the Devops config on add venue -->
        <div
          *ngIf="!venues && venue.VenueId && isSysAdmin"
          id="DevOps"
          class="col-lg-12 col-md-12 col-sm-12 venue-sections"
        >
          <h3>DevOps Configuration</h3>
          <div>
            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
              <label for="SignalrURL"> SignalR Connection URL</label>
              <div>
                <input
                  id="SignalrURL"
                  name="SignalrURL"
                  #SignalrURL="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="SignalR Connection URL"
                  [(ngModel)]="venue.SignalRServiceURL"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div *ngIf="SignalrURL.errors && (SignalrURL.dirty || SignalrURL.touched)" class="alert alert-danger">
                  <div [hidden]="!SignalrURL.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
              <label for="SignalrMsgURL"> SignalR Message URL</label>
              <div>
                <input
                  id="SignalrMsgURL"
                  name="SignalrMsgURL"
                  #SignalrMsgURL="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="SignalR Message URL"
                  [(ngModel)]="venue.SignalRMessageURL"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div
                  *ngIf="SignalrMsgURL.errors && (SignalrMsgURL.dirty || SignalrMsgURL.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!SignalrMsgURL.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="emergency" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Emergency Contact</h3>
          <div>
            <div class="col-lg-8 col-md-8 col-sm-8 form-group">
              <label for="emergencyEmailAddresses"
                ><span id="red-circle" *ngIf="venue.EnableEmergencyAlert">&#x25CF;</span> Emergency Email Addresses
                <span id="red-circle">(Please enter comma separated email addresses)</span></label
              >
              <div *ngIf="!venue.EnableEmergencyAlert">
                <textarea
                  id="emergencyEmailAddresses"
                  name="emergencyEmailAddresses"
                  #emergencyEmailAddresses="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Emergency Email Addresses"
                  [(ngModel)]="venue.EmergencyEmailAddresses"
                  pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},? ?)+$"
                ></textarea>
                <div
                  *ngIf="
                    emergencyEmailAddresses.errors && (emergencyEmailAddresses.dirty || emergencyEmailAddresses.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!emergencyEmailAddresses.errors.pattern" class="text-danger">
                    Emergency email address is invalid.
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="venue.EnableEmergencyAlert">
                <textarea
                  id="emergencyEmailAddresses"
                  name="emergencyemailaddresses"
                  #emergencyEmailAddresses="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Emergency Email Addresses"
                  [(ngModel)]="venue.EmergencyEmailAddresses"
                  required
                  pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},? ?)+$"
                ></textarea>
                <div
                  *ngIf="
                    emergencyEmailAddresses.errors && (emergencyEmailAddresses.dirty || emergencyEmailAddresses.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!emergencyEmailAddresses.errors.required" class="text-danger">
                    Emergency email address is required.
                  </div>
                  <div [hidden]="!emergencyEmailAddresses.errors.pattern" class="text-danger">
                    Emergency email address is invalid.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 form-group">
              <div class="chkbox1">
                <mat-checkbox [(ngModel)]="venue.EnableEmergencyAlert" name="enableEmergencyAlert"
                  >Enable Emergency Alert?</mat-checkbox
                >
              </div>
            </div>
          </div>
        </div>

        <div id="integration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Integration</h3>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="cmmsName">CMMS Integration Type</label>
              <div>
                <ngx-atlas-select
                  (model)="changeCMMSIntegrationtype($event)"
                  [list]="CmmsIntegrationTypes"
                  [idField]="'IntegrationTypeId'"
                  [textField]="'IntegrationType'"
                  [placeholder]="'Select CMMS'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.CmmsIntegrationType.IntegrationTypeId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div
              class="col-lg-6 col-md-6 col-sm-6 form-group"
              *ngFor="let keyData of venue.RelVenueCmmsIntegrationTypeUrls; let i; of: index"
            >
              <div>
                <label
                  *ngIf="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.ControlDataType != 'CheckBox'"
                  for="CmmsIntegrationTypeKey"
                  ><span id="red-circle" *ngIf="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IsRequired == 1"
                    >&#x25CF;</span
                  >
                  {{
                    keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                  }}</label
                >
                <div *ngIf="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.ControlDataType == 'TextBox'">
                  <div [ngClass]="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.isShowEye ? 'input-group' : ''">
                    <input
                      id="CmmsIntegrationType_{{
                        keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyId
                      }}_{{ keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.Sequence }}"
                      name="TextBox_{{ keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.Sequence }}"
                      #TextBox="ngModel"
                      [type]="
                        keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.isShowEye
                          ? keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.isVisible
                            ? 'text'
                            : 'password'
                          : 'text'
                      "
                      class="form-control"
                      placeholder="{{
                        keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                      }}"
                      [(ngModel)]="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyUrl"
                      pattern="^\S+$"
                      [required]="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IsRequired == 1 ? true : false"
                    />
                    <span
                      *ngIf="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.isShowEye"
                      id="eye"
                      class="input-group-addon"
                      (click)="showHideIntegration(keyData.CmmsIntegrationType.CmmsIntegrationTypeKey)"
                    >
                      <i
                        [ngClass]="
                          keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.isVisible ? 'fa fa-eye' : 'fa fa-eye-slash'
                        "
                      ></i>
                    </span>
                  </div>

                  <div *ngIf="TextBox.errors && (TextBox.dirty || TextBox.touched)" class="alert alert-danger">
                    <div [hidden]="!TextBox.errors.required" class="text-danger">
                      {{
                        keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                          | titlecase
                      }}
                      is required.
                    </div>
                    <div [hidden]="!TextBox.errors.pattern" class="text-danger">
                      {{
                        keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                          | titlecase
                      }}
                      is required.
                    </div>
                  </div>
                </div>

                <div *ngIf="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.ControlDataType == 'TextArea'">
                  <textarea
                    id="CmmsIntegrationType_{{
                      keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyId
                    }}_{{ keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.Sequence }}"
                    name="TextArea_{{ keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.Sequence }}"
                    #TextArea="ngModel"
                    class="form-control"
                    placeholder="{{
                      keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                    }}"
                    [(ngModel)]="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyUrl"
                    [required]="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IsRequired == 1 ? true : false"
                  ></textarea>
                  <div *ngIf="TextArea.errors && (TextArea.dirty || TextArea.touched)" class="alert alert-danger">
                    <div [hidden]="!TextArea.errors.required" class="text-danger">
                      {{
                        keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                          | titlecase
                      }}
                      is required.
                    </div>
                    <div [hidden]="!TextArea.errors.pattern" class="text-danger">
                      {{
                        keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                          | titlecase
                      }}
                      is required.
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-md-12 col-sm-12 input-group form-group chkbox1"
                  *ngIf="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.ControlDataType == 'CheckBox'"
                >
                  <mat-checkbox
                    id="CmmsIntegrationType_{{
                      keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyId
                    }}_{{ keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.Sequence }}"
                    name="isActive_{{ keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.Sequence }}"
                    [(ngModel)]="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive"
                    [required]="keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IsRequired == 1 ? true : false"
                  >
                    {{
                      keyData.CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyLabelName.replace('_', ' ')
                    }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="Authentication" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Authentication</h3>
          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="ExpiryDate"><span id="red-circle">&#x25CF;</span> Expiry Date</label>
              <div *ngIf="!venue.VenueId">
                <mat-form-field appearance="outline" style="width: 100%" id="search_crop_name">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    id="search_crop_name"
                    name="ExpiryDate"
                    placeholder="Expiry Date"
                    [(ngModel)]="venue.Subscription.ExpiryDate"
                    #ExpiryDate="ngModel"
                    [min]="TodayDate"
                    [max]="9999 - 12 - 31"
                    required
                    style="border: 1px solid #ecf0f5"
                    readonly
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div *ngIf="ExpiryDate.errors && (ExpiryDate.dirty || ExpiryDate.touched)" class="alert alert-danger">
                  <div [hidden]="!ExpiryDate.errors.required" class="text-danger">Expiry date is required.</div>
                </div>
              </div>

              <div *ngIf="venue.VenueId">
                <mat-form-field appearance="outline" style="width: 100%" id="search_crop_name">
                  <input
                    matInput
                    [matDatepicker]="picker"
                    id="search_crop_name"
                    name="ExpiryDate"
                    placeholder="Expiry Date"
                    [(ngModel)]="venue.Subscription.ExpiryDate"
                    #ExpiryDate="ngModel"
                    [max]="9999 - 12 - 31"
                    required
                    style="border: 1px solid #ecf0f5"
                    readonly
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div *ngIf="ExpiryDate.errors && (ExpiryDate.dirty || ExpiryDate.touched)" class="alert alert-danger">
                  <div [hidden]="!ExpiryDate.errors.required" class="text-danger">Expiry date is required.</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="AuthenticationType"> Authentication Type</label>
              <div>
                <ngx-atlas-select
                  (model)="changeAuthenticationtype($event)"
                  [list]="authenticationTypes"
                  [idField]="'AuthenticationTypeId'"
                  [textField]="'AuthenticatonTypeName'"
                  [placeholder]="'Select Authentication Type'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.AuthenticationType.AuthenticationTypeId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div
              class="col-lg-12 col-md-12 col-sm-12"
              *ngIf="relVenueAuthentication != null && relVenueAuthentication.length > 0"
            >
              <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                <div
                  class="col-lg-12 col-md-12 col-sm-12 form-group"
                  *ngFor="let keyData of relVenueAuthentication; let i; of: index"
                >
                  <label for="authenticationTypekey"
                    ><span id="red-circle">&#x25CF;</span>
                    {{
                      keyData.AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyName.replace('_', ' ')
                    }}</label
                  >
                  <div>
                    <div class="input-group">
                      <input
                        id="authenticationType_{{
                          keyData.AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyId
                        }}"
                        name="authenticationTypeKeyName_{{ keyData.AuthenticationType.AuthenticationTypeKey.Sequence }}"
                        #authenticationTypeKeyValue="ngModel"
                        [type]="keyData.AuthenticationType.AuthenticationTypeKey.isVisible ? 'text' : 'password'"
                        class="form-control"
                        placeholder="{{ keyData.AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyName }}"
                        [(ngModel)]="keyData.AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyValue"
                        pattern="^\S+$"
                        required
                      />
                      <span
                        id="eye"
                        class="input-group-addon"
                        (click)="showHide(keyData.AuthenticationType.AuthenticationTypeKey)"
                        ><i
                          [ngClass]="
                            keyData.AuthenticationType.AuthenticationTypeKey.isVisible ? 'fa fa-eye' : 'fa fa-eye-slash'
                          "
                        ></i
                      ></span>
                    </div>
                    <div
                      *ngIf="
                        authenticationTypeKeyValue.errors &&
                        (authenticationTypeKeyValue.dirty || authenticationTypeKeyValue.touched)
                      "
                      class="alert alert-danger"
                    >
                      <div [hidden]="!authenticationTypeKeyValue.errors.required" class="text-danger">
                        {{
                          keyData.AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyName.replace('_', ' ')
                            | titlecase
                        }}
                        is required.
                      </div>
                      <div [hidden]="!authenticationTypeKeyValue.errors.pattern" class="text-danger">
                        {{
                          keyData.AuthenticationType.AuthenticationTypeKey.AuthenticationTypeKeyName.replace('_', ' ')
                            | titlecase
                        }}
                        is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="SubscriptionType">Subscription Type</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ venue.Subscription.SubscriptionType }}</p>
                </div>
                <!--<ngx-atlas-select (model)="subscriptionType($event)"
                                                  [list]="subscriptions"
                                                  [idField]="'SubscriptionId'"
                                                  [textField]="'SubscriptionType'"
                                                  [placeholder]="'Select Subscription Type'"
                                                  [allowClear]="true"
                                                  [multiple]="false"
                                                  [required]="true"
                                                  [selectedValue]="venue.Subscription.SubscriptionId">
                                </ngx-atlas-select>-->
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="IsPassive">
              <div class="form-group">
                <div class="chkbox1">
                  <mat-checkbox name="isPassive" [checked]="isChecked" (change)="changeIsPassive($event)">
                    Is Passive?</mat-checkbox
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="!map_for">
              <div class="p-left" *ngIf="isTime">
                <mat-checkbox name="scanTime" [checked]="isScanTime" (change)="changeScanTime($event)">
                  Beacon Scan Time(min)</mat-checkbox
                >
              </div>
              <div class="p-left" *ngIf="isSTime">
                <input
                  id="beaconScanTime"
                  name="beaconScanTime"
                  #beaconScanTime="ngModel"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="Beacon Scan Time(min)"
                  [(ngModel)]="venue.MaxBeaconScanTime"
                  autocomplete="off"
                  [required]="isSTime"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="!map_for">
              <div *ngIf="isTime">
                <mat-checkbox name="cTime" [checked]="isCompletionTime" (change)="changeCompletionTime($event)">
                  Cleaning Completion Time(min)</mat-checkbox
                >
              </div>

              <div *ngIf="isCTime">
                <input
                  id="cleaningCompletionTime"
                  name="cleaningCompletionTime"
                  #cleaningCompletionTime="ngModel"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder="Cleaning Completion Time(min)"
                  [(ngModel)]="venue.MaxCleaningAlertCompletionTime"
                  autocomplete="off"
                  [required]="isCTime"
                />
              </div>
            </div>
          </div>
        </div>

        <div id="GuestFeedbackConig" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Touchless Guest Feedback Configuration</h3>
          <div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <div>
                  <mat-checkbox
                    name="EnableTouchlessFeedback"
                    [(ngModel)]="venue.EnableTouchlessFeedback"
                    (change)="enableTouchlessFeedback($event)"
                    >Enable Touchless Guest Feedback</mat-checkbox
                  >&nbsp;&nbsp;
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isTouchlessFeedbackEnable">
              <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <mat-checkbox name="EnableTouchlessFeedbackReason" [(ngModel)]="venue.EnableTouchlessFeedbackReason"
                  >Enable Reasons</mat-checkbox
                >
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <mat-checkbox name="EnableTouchlessFeedbackComment" [(ngModel)]="venue.EnableTouchlessFeedbackComment"
                  >Enable Comments</mat-checkbox
                >
              </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isTouchlessFeedbackEnable">
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <mat-checkbox name="IsNotificationForNegativeFeedbackEnabled" [(ngModel)]="venue.IsNotificationForNegativeFeedbackEnabled" (change)="enableNegativeFeedbackNotification($event)">Enable Negative Feedback Notifications On Mobile</mat-checkbox>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label for="negativeFeedback"><span id="red-circle" *ngIf="venue.IsNotificationForNegativeFeedbackEnabled">&#x25CF;</span> After How Many Negative Feedbacks?</label>
                                <div>
                                    <input id="negativeFeedback" name="negativeFeedback" #negativeFeedback="ngModel" type="number" class="form-control" placeholder="Enter Number"
                                           [(ngModel)]="venue.NumberOfNegativeFeedbacksForNotification" min="1" max="999" maxlength="3" pattern="^[1-9][0-9]*$" [required]="venue.IsNotificationForNegativeFeedbackEnabled"
                                           [disabled]="!venue.IsNotificationForNegativeFeedbackEnabled" (keypress)="CheckBadgeValues($event)" />
                                    <div *ngIf="negativeFeedback.errors && (negativeFeedback.dirty || negativeFeedback.touched)" class="alert alert-danger">
                                        <div [hidden]="!negativeFeedback.errors.required" class="text-danger">
                                            Negative feedback value is required.
                                        </div>
                                        <div [hidden]="!negativeFeedback.errors.pattern" class="text-danger">
                                            Negative feedback accept only numeric  and greater than 0 value.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>

        <div id="NegativeFeedbackConfig" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Negative Feedback Configuration (Touchless & Avius)</h3>
          <div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <mat-checkbox
                  name="IsNotificationForNegativeFeedbackEnabled"
                  [(ngModel)]="venue.IsNotificationForNegativeFeedbackEnabled"
                  (change)="enableNegativeFeedbackNotification($event)"
                >
                  Enable Negative Feedback Notifications On Mobile
                </mat-checkbox>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                <label for="negativeFeedback">
                  <span id="red-circle" *ngIf="venue.IsNotificationForNegativeFeedbackEnabled">&#x25CF;</span> After How
                  Many Negative Feedbacks?
                </label>
                <div>
                  <input
                    id="negativeFeedback"
                    name="negativeFeedback"
                    #negativeFeedback="ngModel"
                    type="number"
                    class="form-control"
                    placeholder="Enter Number"
                    [(ngModel)]="venue.NumberOfNegativeFeedbacksForNotification"
                    min="1"
                    max="999"
                    maxlength="3"
                    pattern="^[1-9][0-9]*$"
                    [required]="venue.IsNotificationForNegativeFeedbackEnabled"
                    [disabled]="!venue.IsNotificationForNegativeFeedbackEnabled"
                    (keypress)="CheckBadgeValues($event)"
                  />
                  <div
                    *ngIf="negativeFeedback.errors && (negativeFeedback.dirty || negativeFeedback.touched)"
                    class="alert alert-danger"
                  >
                    <div [hidden]="!negativeFeedback.errors.required" class="text-danger">
                      Negative feedback value is required.
                    </div>
                    <div [hidden]="!negativeFeedback.errors.pattern" class="text-danger">
                      Negative feedback accept only numeric and greater than 0 value.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="Maintenance" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Cleaning Alert/Work Order Default Configuration</h3>
          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Priority">Priority</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.WorkorderPriority.PriorityId = $event"
                  [list]="priorities"
                  [idField]="'PriorityId'"
                  [textField]="'PriorityLevel'"
                  [placeholder]="'Select Priority'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.WorkorderPriority.PriorityId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Severity">Severity</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.WorkorderSeverity.SeverityId = $event"
                  [list]="severities"
                  [idField]="'SeverityId'"
                  [textField]="'SeverityLevel'"
                  [placeholder]="'Select Severity'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.WorkorderSeverity.SeverityId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Pins">Pin</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.Pin.PinId = $event"
                  [list]="pins"
                  [idField]="'PinId'"
                  [textField]="'PinColor'"
                  [placeholder]="'Select Pin'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.Pin.PinId"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <div class="chkbox1">
                <mat-checkbox [(ngModel)]="venue.IsMaintenanceQcEnable" name="IsMaintenanceQcEnable"
                  >Enable Maintenance QC</mat-checkbox
                >
              </div>
            </div>

            <!--<div class="col-lg-12 col-md-12 col-sm-12 form-section">
                            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                                <div>
                                    <mat-checkbox [(ngModel)]="venue.IsCustodialQcEnable" name="IsCustodialQcEnable">Enable Custodial QC</mat-checkbox>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                                <div>
                                    <mat-checkbox [(ngModel)]="venue.IsMaintenanceQcEnable" name="IsMaintenanceQcEnable">Enable Maintenance QC</mat-checkbox>
                                </div>
                            </div>
                        </div>-->
          </div>
        </div>

        <div id="BeaconConfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Beacon Configuration</h3>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="BeaconManufacturer"><span id="red-circle">&#x25CF;</span> Beacon Manufacturer</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.BeaconManufacturer = $event"
                  [list]="beaconManufacturers"
                  [idField]="'textfield'"
                  [textField]="'textfield'"
                  [placeholder]="'Select Beacon Manufacturer'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="venue.BeaconManufacturer"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="thresholdBeaconInRange">Threshold Beacon In Range (minute)</label>
              <div>
                <input
                  id="thresholdBeaconInRange"
                  name="thresholdBeaconInRange"
                  #thresholdBeaconInRange="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Threshold Beacon In Range"
                  [(ngModel)]="venue.ThresholdBeaconInRange"
                  min="0"
                  pattern="[0-9]*$"
                />
                <div
                  *ngIf="
                    thresholdBeaconInRange.errors && (thresholdBeaconInRange.dirty || thresholdBeaconInRange.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!thresholdBeaconInRange.errors.pattern" class="text-danger">
                    Threshold beacon in range accept only numeric value.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="thresholdBeaconNotInRange">Threshold Beacon Not In Range (minute)</label>
              <div>
                <input
                  id="thresholdBeaconNotInRange"
                  name="thresholdBeaconNotInRange"
                  #thresholdBeaconNotInRange="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Threshold Beacon Not In Range"
                  [(ngModel)]="venue.ThresholdBeaconNotInRange"
                  min="0"
                  pattern="[0-9]*$"
                />
                <div
                  *ngIf="
                    thresholdBeaconNotInRange.errors &&
                    (thresholdBeaconNotInRange.dirty || thresholdBeaconNotInRange.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!thresholdBeaconNotInRange.errors.pattern" class="text-danger">
                    Threshold beacon not in range accept only numeric value.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="SensorConfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Sensor Configuration</h3>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Threshold">Default Threshold</label>
              <div>
                <input
                  id="Threshold"
                  name="Threshold"
                  #Threshold="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Default Threshold"
                  [(ngModel)]="venue.Threshold"
                  maxlength="249"
                  autocomplete="off"
                  pattern="^[1-9][0-9]*$"
                />
                <div *ngIf="Threshold.errors && (Threshold.dirty || Threshold.touched)" class="alert alert-danger">
                  <div [hidden]="!Threshold.errors.pattern" class="text-danger">
                    Sensor Threshold does not accepting value less than 0.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="WarnPercentage">Default Warn Percentage</label>
              <div>
                <input
                  id="WarnPercentage"
                  name="WarnPercentage"
                  #WarnPercentage="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Default Warn Percentage"
                  [(ngModel)]="venue.WarnPercentage"
                  maxlength="249"
                  autocomplete="off"
                  pattern="^[1-9][0-9]*$"
                />
                <div
                  *ngIf="WarnPercentage.errors && (WarnPercentage.dirty || WarnPercentage.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!WarnPercentage.errors.pattern" class="text-danger">
                    Sensor Warn % does not accepting value less than 0.
                  </div>
                </div>
              </div>
            </div>

            <!--<div class="col-lg-6 col-md-6 col-sm-6 form-group">
                            <label for="CleaningAlertTriggerThreshold">Cleaning Alert Trigger Threshold</label>
                            <div>
                                <input id="CleaningAlertTriggerThreshold" name="CleaningAlertTriggerThreshold" #CleaningAlertTriggerThreshold="ngModel" type="number" class="form-control" placeholder="Cleaning Alert Trigger Threshold"
                                       [(ngModel)]="venue.CleaningAlertTriggerThreshold" maxlength="249" autocomplete="off" pattern="^[1-9][0-9]*$" />
                            </div>
                        </div>-->
          </div>
        </div>

        <div id="UserAssignmentConfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>User Assignment Configuration</h3>

          <div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <!--<div>
                                <label class="radio-inline">
                                    <input type="radio" name="optradio1" value="1" [(ngModel)]="ShiftStaffSchedule" (change)="checkUserAssignmentRule(ShiftStaffSchedule)" /><b>Staff Schedule</b>
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" name="optradio2" value="2" [(ngModel)]="LocationUserAssignment" (change)="checkUserAssignmentRule(LocationUserAssignment)" /><b>User Self-Assign</b>
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" name="optradio3" value="3" [(ngModel)]="EitherOneOrThese" (change)="checkUserAssignmentRule(EitherOneOrThese)" /><b>Either One Of These</b>
                                    &nbsp;&nbsp;<span id="red-circle">(Please select user assignment configuration.)</span>
                                </label>
                            </div>-->
                <mat-radio-group name="optradio1" aria-label="User Assignment Configuration">
                  <mat-radio-button
                    ngDefaultControl
                    [ngModelOptions]="{ standalone: true }"
                    value="1"
                    [(ngModel)]="ShiftStaffSchedule"
                    (change)="checkUserAssignmentRule(1)"
                    [checked]="isShiftStaffSchedule"
                    ><b>Staff Schedule</b></mat-radio-button
                  >&nbsp;&nbsp;
                  <mat-radio-button
                    ngDefaultControl
                    [ngModelOptions]="{ standalone: true }"
                    value="2"
                    [(ngModel)]="LocationUserAssignment"
                    (change)="checkUserAssignmentRule(2)"
                    [checked]="isLocationUserAssignment"
                    ><b>User Self-Assign</b></mat-radio-button
                  >&nbsp;&nbsp;
                  <mat-radio-button
                    ngDefaultControl
                    [ngModelOptions]="{ standalone: true }"
                    value="3"
                    [(ngModel)]="EitherOneOrThese"
                    (change)="checkUserAssignmentRule(3)"
                    [checked]="isEitherOneOrThese"
                    ><b>Either One Of These</b></mat-radio-button
                  >&nbsp;&nbsp; &nbsp;&nbsp;<span id="red-circle" style="font-weight: bold"
                    >(Please select user assignment configuration.)</span
                  >
                </mat-radio-group>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <div>
                  <mat-checkbox name="OnDuty" [(ngModel)]="OnDuty"> On Duty</mat-checkbox>&nbsp;&nbsp;
                  <mat-checkbox name="LocationStaffGender" [(ngModel)]="LocationStaffGender">
                    Assign Locations by Gender</mat-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="GoalConfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Goals Configuration</h3>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="InspectionScoreGoal">Inspection Score (%)</label>
              <div>
                <input
                  id="InspectionScoreGoal"
                  name="InspectionScoreGoal"
                  #InspectionScoreGoal="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Inspection Score"
                  [(ngModel)]="venue.InspectionScoreGoal"
                  pattern="^(?:100|[1-9]?[0-9])$"
                  min="0"
                  max="100"
                  autocomplete="off"
                />
                <div
                  *ngIf="InspectionScoreGoal.errors && (InspectionScoreGoal.dirty || InspectionScoreGoal.touched)"
                  class="alert alert-danger"
                >
                  <div [hidden]="!InspectionScoreGoal.errors.pattern" class="text-danger">
                    Inspection Score should be 0 to 100.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="NumberOfInspectionsGoal">Number of Inspections per Day </label>
              <div>
                <input
                  id="NumberOfInspectionsGoal"
                  name="NumberOfInspectionsGoal"
                  #NumberOfInspectionsGoal="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Number of Inspections per Day"
                  [(ngModel)]="venue.NumberOfInspectionsGoal"
                  min="1"
                  max="999"
                  autocomplete="off"
                  pattern="^(?:[1-9]|[1-9][0-9]|[1-9][0-9][0-9])$"
                />
                <div
                  *ngIf="
                    NumberOfInspectionsGoal.errors && (NumberOfInspectionsGoal.dirty || NumberOfInspectionsGoal.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!NumberOfInspectionsGoal.errors.pattern" class="text-danger">
                    Number of Inspections per Day should be 1 to 999.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="ServiceTicketResponseTimeGoal">Cleaning Alert Response Goal (min)</label>
              <div>
                <input
                  id="ServiceTicketResponseTimeGoal"
                  name="ServiceTicketResponseTimeGoal"
                  #ServiceTicketResponseTimeGoal="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Cleaning Alert Response Goal in mins"
                  [(ngModel)]="venue.ServiceTicketResponseTimeGoal"
                  pattern="^(?:[0-9]|[0-9][0-9]|[0-9][0-9][0-9])$"
                  min="0"
                  max="999"
                  autocomplete="off"
                />
                <div
                  *ngIf="
                    ServiceTicketResponseTimeGoal.errors &&
                    (ServiceTicketResponseTimeGoal.dirty || ServiceTicketResponseTimeGoal.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!ServiceTicketResponseTimeGoal.errors.pattern" class="text-danger">
                    Cleaning Alert Response Goal in mins should be 0 to 999.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="NumberOfServiceTicketsGoal">Daily Cleaning Alerts</label>
              <div>
                <input
                  id="NumberOfServiceTicketsGoal"
                  name="NumberOfServiceTicketsGoal"
                  #NumberOfServiceTicketsGoal="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="Daily Cleaning Alerts"
                  [(ngModel)]="venue.NumberOfServiceTicketsGoal"
                  autocomplete="off"
                  min="0"
                  max="999"
                  pattern="^[0-9][0-9]{0,2}$"
                />
                <div
                  *ngIf="
                    NumberOfServiceTicketsGoal.errors &&
                    (NumberOfServiceTicketsGoal.dirty || NumberOfServiceTicketsGoal.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!NumberOfServiceTicketsGoal.errors.pattern" class="text-danger">
                    Daily Cleaning Alerts should be 0 to 999.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="FeedbackRatingGoal">Feedback Rating Goal (%)</label>
            <div>
              <input
                id="FeedbackRatingGoal"
                name="FeedbackRatingGoal"
                #FeedbackRatingGoal="ngModel"
                type="number"
                class="form-control"
                placeholder="Feedback Rating Goal"
                [(ngModel)]="venue.FeedbackRatingGoal"
                pattern="^(?:100|[1-9]?[0-9])$"
                min="0"
                max="100"
                autocomplete="off"
              />
              <div
                *ngIf="FeedbackRatingGoal.errors && (FeedbackRatingGoal.dirty || FeedbackRatingGoal.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!FeedbackRatingGoal.errors.pattern" class="text-danger">
                  Feedback Rating Goal should be 0 to 100.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="PowerBIReportConfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Power BI Configuration</h3>
          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Throughput">Throughput Summary Report URL</label>
              <div>
                <input
                  id="Throughput"
                  name="Throughput"
                  #Throughput="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Throughput Summary Report URL"
                  [(ngModel)]="venue.RelVenuePowerBI.ThroughputReportURL"
                  autocomplete="off"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div *ngIf="Throughput.errors && (Throughput.dirty || Throughput.touched)" class="alert alert-danger">
                  <div [hidden]="!Throughput.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Cleaning">Cleaning Summary Report URL</label>
              <div>
                <input
                  id="Cleaning"
                  name="Cleaning"
                  #Cleaning="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Cleaning Summary Report URL"
                  [(ngModel)]="venue.RelVenuePowerBI.CleaningReportURL"
                  autocomplete="off"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div *ngIf="Cleaning.errors && (Cleaning.dirty || Cleaning.touched)" class="alert alert-danger">
                  <div [hidden]="!Cleaning.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Survey">Survey Summary Report URL</label>
              <div>
                <input
                  id="Survey"
                  name="Survey"
                  #Survey="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Survey Summary Report URL"
                  [(ngModel)]="venue.RelVenuePowerBI.SurveyReportURL"
                  autocomplete="off"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div *ngIf="Survey.errors && (Survey.dirty || Survey.touched)" class="alert alert-danger">
                  <div [hidden]="!Survey.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Predictive">Predictive Summary Report URL</label>
              <div>
                <input
                  id="Predictive"
                  name="Predictive"
                  #Predictive="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Predictive Summary Report URL"
                  [(ngModel)]="venue.RelVenuePowerBI.PredictiveReportURL"
                  autocomplete="off"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div *ngIf="Predictive.errors && (Predictive.dirty || Predictive.touched)" class="alert alert-danger">
                  <div [hidden]="!Predictive.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Survey">Inspection Summary Report URL</label>
              <div>
                <input
                  id="InspectionSummaryReportURL"
                  name="InspectionSummaryReportURL"
                  #InspectionSummaryReportURL="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Inspection Summary Report URL"
                  [(ngModel)]="venue.RelVenuePowerBI.InspectionSummaryReportURL"
                  autocomplete="off"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div
                  *ngIf="
                    InspectionSummaryReportURL.errors &&
                    (InspectionSummaryReportURL.dirty || InspectionSummaryReportURL.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!InspectionSummaryReportURL.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="Survey">Zurn Flush Valve Summary Report URL</label>
              <div>
                <input
                  id="ZurnFlushValveSummaryReportURL"
                  name="ZurnFlushValveSummaryReportURL"
                  #ZurnFlushValveSummaryReportURL="ngModel"
                  type="text"
                  class="form-control"
                  placeholder="Zurn Flush Valve Summary Report URL"
                  [(ngModel)]="venue.RelVenuePowerBI.ZurnFlushValveSummaryReportURL"
                  autocomplete="off"
                  [pattern]="utilitiesService.webSiteRegex"
                  [readonly]="!isSysAdmin"
                />
                <div
                  *ngIf="
                    ZurnFlushValveSummaryReportURL.errors &&
                    (ZurnFlushValveSummaryReportURL.dirty || ZurnFlushValveSummaryReportURL.touched)
                  "
                  class="alert alert-danger"
                >
                  <div [hidden]="!ZurnFlushValveSummaryReportURL.errors.pattern" class="text-danger">
                    {{ utilitiesService.webSiteRegexMessage }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="configurationReset" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Configuration Reset</h3>
          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="ThresholdReset">Threshold Reset</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.ThresholdReset = $event"
                  [list]="thresholdResets"
                  [idField]="'textfield'"
                  [textField]="'textfield'"
                  [placeholder]="'Select Threshold Reset'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.ThresholdReset"
                >
                </ngx-atlas-select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="FeedbackReset">Guest Feedback Reset</label>
              <div>
                <ngx-atlas-select
                  (model)="venue.FeedbackReset = $event"
                  [list]="feedbackResets"
                  [idField]="'textfield'"
                  [textField]="'textfield'"
                  [placeholder]="'Select Guest Feedback Reset'"
                  [allowClear]="true"
                  [multiple]="false"
                  [selectedValue]="venue.FeedbackReset"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <mat-checkbox name="CreateAlertOnThresholdBreach" [(ngModel)]="venue.CreateAlertOnThresholdBreach"
              >Create Alert On Threshold Breach</mat-checkbox
            >
          </div>
        </div>

        <div id="digitalDisplay" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Digital Display</h3>
          <div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <div>
                  <mat-checkbox name="EnableLocationStatus" [(ngModel)]="venue.EnableLocationStatus"
                    >Enable Location Status</mat-checkbox
                  >&nbsp;&nbsp;
                  <mat-checkbox name="EnableDigitalScreen" [(ngModel)]="venue.EnableDigitalScreen"
                    >Enable Digital Screen</mat-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="badgeconfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Badge Configuration</h3>
          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <mat-checkbox [(ngModel)]="venue.IsBadgeEnable" name="IsBadgeEnable">Enable Badge Solution?</mat-checkbox>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <mat-checkbox [(ngModel)]="venue.HideScheduleCA" name="HideScheduleCA"
                >Hide Cleaning Alert Schedules On Calendar?</mat-checkbox
              >
            </div>
          </div>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="KontaktVenueId"
                ><span id="red-circle" *ngIf="venue.IsBadgeEnable">&#x25CF;</span> Venue Id (Kontakt)</label
              >
              <input
                id="KontaktVenueId"
                name="KontaktVenueId"
                #KontaktVenueId="ngModel"
                type="text"
                class="form-control"
                placeholder="Venue Id (Kontakt)"
                [(ngModel)]="venue.KontaktVenueId"
                autocomplete="off"
                [required]="venue.IsBadgeEnable"
                [disabled]="!venue.IsBadgeEnable"
              />
              <div
                *ngIf="KontaktVenueId.errors && (KontaktVenueId.dirty || KontaktVenueId.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!KontaktVenueId.errors.required" class="text-danger">Venue id is required.</div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="KontaktAPIKey"
                ><span id="red-circle" *ngIf="venue.IsBadgeEnable">&#x25CF;</span> API Key (Kontakt)</label
              >
              <input
                id="KontaktAPIKey"
                name="KontaktAPIKey"
                #KontaktAPIKey="ngModel"
                type="text"
                class="form-control"
                placeholder="API Key (Kontakt)"
                [(ngModel)]="venue.KontaktAPIKey"
                autocomplete="off"
                [required]="venue.IsBadgeEnable"
                [disabled]="!venue.IsBadgeEnable"
              />
              <div
                *ngIf="KontaktAPIKey.errors && (KontaktAPIKey.dirty || KontaktAPIKey.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!KontaktAPIKey.errors.required" class="text-danger">API key is required.</div>
              </div>
            </div>
          </div>
        </div>

        <div id="surveyconfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Survey Configuration</h3>
          <div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                <label for="FeedbackReset">Consider Incomplete Surveys For Scoring</label><br />
                <div style="padding-top: 7px">
                  <mat-radio-group
                    name="Consider Completed Survey For Scoring"
                    aria-label="Consider Completed Survey For Scoring"
                  >
                    <mat-radio-button
                      ngDefaultControl
                      [ngModelOptions]="{ standalone: true }"
                      value="1"
                      (change)="checkSurveyScoring(1)"
                      [(ngModel)]="ConsiderNonCompletedSurveyYes"
                      [checked]="isConsiderNonCompletedSurveyYes"
                      ><b>Yes</b></mat-radio-button
                    >&nbsp;&nbsp;
                    <mat-radio-button
                      ngDefaultControl
                      [ngModelOptions]="{ standalone: true }"
                      value="0"
                      (change)="checkSurveyScoring(0)"
                      [(ngModel)]="ConsiderNonCompletedSurveyNo"
                      [checked]="isConsiderNonCompletedSurveyNo"
                      ><b>No</b></mat-radio-button
                    >&nbsp;&nbsp;
                  </mat-radio-group>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                <label for="FeedbackReset">Consider Neutral Surveys In</label>
                <div>
                  <ngx-atlas-select
                    (model)="venue.ConsiderNeutralSurvey = $event"
                    [list]="surveyQuestionResponseTypes"
                    [idField]="'SurveyQuestionResponseTypeName'"
                    [textField]="'SurveyQuestionResponseTypeName'"
                    [placeholder]="'Response Type'"
                    [allowClear]="false"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="venue.ConsiderNeutralSurvey"
                  >
                  </ngx-atlas-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="eventconfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Event Configuration</h3>
          <div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <mat-checkbox name="EnableEquipmentPMEvent" [(ngModel)]="venue.IsEquipmentPMEventEnabled"
                  >Enable "Equipment PM" Event</mat-checkbox
                >
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <mat-checkbox name="EnableProjectWorkEvent" [(ngModel)]="venue.IsProjectWorkEventEnabled"
                  >Enable "Project Work" Event</mat-checkbox
                >
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                <div *ngIf="venue.IsEquipmentPMEventEnabled">
                  <label for="EquimentPMConfigureTime"> Configure the time to create "Equipment PM" notification</label>
                  <input
                    (keypress)="keyPressCheck($event)"
                    type="number"
                    id="EquimentPMConfigureTime"
                    name="EquimentPMConfigureTime"
                    min="1"
                    max="9999"
                    class="form-control eventConfigTextBox"
                    [(ngModel)]="venue.EquimentPMConfigureTime"
                    #EquimentPMConfigureTime="ngModel"
                    placeholder="Time in minute"
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      EquimentPMConfigureTime.errors &&
                      (EquimentPMConfigureTime.dirty || EquimentPMConfigureTime.touched)
                    "
                    class="alert alert-danger"
                    style="padding: 5px"
                  >
                    <div [hidden]="!EquimentPMConfigureTime.errors.pattern" class="text-danger">
                      Accepts only numeric and greater than 0 values.
                    </div>
                  </div>

                  <!--<div style="display: flex;">
                                        <span style="margin-right: 5px;margin-top: auto;margin-bottom: auto;">Configure the time</span>
                                        <input id="EquimentPMConfigureTime" name="EquimentPMConfigureTime" #EquimentPMConfigureTime="ngModel" type="text" class="form-control eventConfigTextBox" placeholder="Time in minute"
                                               [(ngModel)]="venue.EquimentPMConfigureTime" autocomplete="off" maxlength="5" pattern="^[1-9][0-9]*$" [required]="venue.IsEquipmentPMEventEnabled" style="width: 45%" />
                                        <span style="margin-left: 5px;margin-top: auto;margin-bottom: auto;">minute</span>
                                    </div>
                                    <div *ngIf="EquimentPMConfigureTime.errors && (EquimentPMConfigureTime.dirty || EquimentPMConfigureTime.touched)" class="alert alert-danger" style="padding:5px;">
                                        <div [hidden]="!EquimentPMConfigureTime.errors.required" class="text-danger">
                                            Equiment pm time value is required.
                                        </div>

                                        <div [hidden]="!EquimentPMConfigureTime.errors.pattern" class="text-danger">
                                            Accepts only numeric and greater than 0 values.
                                        </div>
                                    </div>-->
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                <div *ngIf="venue.IsProjectWorkEventEnabled">
                  <label for="ProjectWorkConfigureTime">
                    Configure the time to create "Project Work" notification</label
                  >
                  <input
                    (keypress)="keyPressCheck($event)"
                    type="number"
                    id="ProjectWorkConfigureTime"
                    name="ProjectWorkConfigureTime"
                    min="1"
                    max="9999"
                    class="form-control eventConfigTextBox"
                    [(ngModel)]="venue.ProjectWorkConfigureTime"
                    #ProjectWorkConfigureTime="ngModel"
                    placeholder="Time in minute"
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      ProjectWorkConfigureTime.errors &&
                      (ProjectWorkConfigureTime.dirty || ProjectWorkConfigureTime.touched)
                    "
                    class="alert alert-danger"
                    style="padding: 5px"
                  >
                    <div [hidden]="!ProjectWorkConfigureTime.errors.pattern" class="text-danger">
                      Accepts only numeric and greater than 0 values.
                    </div>
                  </div>

                  <!--<div style="display: flex;">
                                        <span style="margin-right: 5px;margin-top: auto;margin-bottom: auto;">Configure the time</span>
                                        <input id="ProjectWorkConfigureTime" name="ProjectWorkConfigureTime" #ProjectWorkConfigureTime="ngModel" type="text" class="form-control eventConfigTextBox" placeholder="Time in minute"
                                               [(ngModel)]="venue.ProjectWorkConfigureTime" autocomplete="off" maxlength="5" pattern="^[1-9][0-9]*$" [required]="venue.IsProjectWorkEventEnabled" style="width: 45%" />
                                        <span style="margin-left: 5px;margin-top: auto;margin-bottom: auto;">minute</span>
                                    </div>
                                    <div *ngIf="ProjectWorkConfigureTime.errors && (ProjectWorkConfigureTime.dirty || ProjectWorkConfigureTime.touched)" class="alert alert-danger" style="padding:5px;">
                                        <div [hidden]="!ProjectWorkConfigureTime.errors.required" class="text-danger">
                                            Project work time value is required.
                                        </div>

                                        <div [hidden]="!ProjectWorkConfigureTime.errors.pattern" class="text-danger">
                                            Accepts only numeric and greater than 0 values.
                                        </div>
                                    </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="gatesConfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Gates Configuration</h3>

          <div>
            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="FlightGateLicensesCount"><span id="red-circle" *ngIf="isFlightDataEnabled">&#x25CF;</span> Licensed for Flight Departure Alerts</label>
              <div>
                <input
                  id="FlightGateLicensesCount"
                  name="FlightGateLicensesCount"
                  #FlightGateLicensesCount="ngModel"
                  type="number"
                  class="form-control"
                  placeholder="License Count"
                  [(ngModel)]="venue.FlightGateLicensesCount"
                  min="0"
                  autocomplete="off"
                  [required]="isFlightDataEnabled"
                  [disabled]="!isSysAdmin"
                />
                <div *ngIf="FlightGateLicensesCount.errors && (FlightGateLicensesCount.dirty || FlightGateLicensesCount.touched)" class="text-danger">
                  <div *ngIf="FlightGateLicensesCount.errors['required']">License Count is required.</div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
              <label for="UsedFlightDepartureLicenses">Flight Departure Licenses In Use</label>
              <div>
                <input
                  id="UsedFlightDepartureLicenses"
                  name="UsedFlightDepartureLicenses"
                  #NumberOfInspectionsGoal="ngModel"
                  type="number"
                  class="form-control"
                  [(ngModel)]="venue.UsedFlightDepartureLicenses"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div id="FeatureConfiguration" class="col-lg-12 col-md-12 col-sm-12 venue-sections">
          <h3>Feature Configuration</h3>
          <div class="col-lg-8 col-md-8 col-sm-8 accessPermissns">
            <div class="d-inline-block form-group">
              <div *ngIf="isCheckBoxVisible" style="margin-left: -15px">
                <mat-checkbox
                  class="form-check-input"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="isAllFeatureConfigurationChecked"
                  (change)="onAllCheckedChange($event)"
                  >All</mat-checkbox
                >
              </div>
              <ngx-treeview
                #treeviewComponent
                [items]="items"
                [config]="config"
                [itemTemplate]="itemTemplate"
                (selectedChange)="onSelectedChange($event)"
              >
              </ngx-treeview>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-4 col-sm-4"
            *ngIf="featureConfigText && featureConfigText.length > 0"
            style="max-height: 500px; overflow-y: auto"
          >
            <div *ngFor="let featureText of featureConfigText">
              <div id="text_{{ featureText.FeatureConfigurationId }}" class="form-group">
                <label
                  *ngIf="
                    featureText.FeatureConfigurationCode != 'CA_DISPLAY_CLEANING_ALERT_AFTER_BEACON_SCAN' &&
                    featureText.FeatureConfigurationCode != 'QC_DISPLAY_AFTER_BEACON_SCAN' &&
                    featureText.FeatureConfigurationCode != 'INS_COMPLETION_CRITERIA_BEACON_SCANNING'
                  "
                  id="text_{{ featureText.FeatureConfigurationId }}"
                  >{{ featureText.FeatureConfigurationName }}</label
                >

                <label
                  *ngIf="featureText.FeatureConfigurationCode == 'CA_DISPLAY_CLEANING_ALERT_AFTER_BEACON_SCAN'"
                  id="text_{{ featureText.FeatureConfigurationId }}"
                  >Allow to process if Cleaning Alert beacon not scan within
                </label>
                <label
                  *ngIf="featureText.FeatureConfigurationCode == 'QC_DISPLAY_AFTER_BEACON_SCAN'"
                  id="text_{{ featureText.FeatureConfigurationId }}"
                  >Allow to process if Quality Check beacon not scan within
                </label>
                <label
                  *ngIf="featureText.FeatureConfigurationCode == 'INS_COMPLETION_CRITERIA_BEACON_SCANNING'"
                  id="text_{{ featureText.FeatureConfigurationId }}"
                  >Allow to process if Inspection beacon not scan within
                </label>

                <div
                  class="col-lg-12 col-lg-12 col-sm-12"
                  *ngIf="
                    featureText.FeatureConfigurationCode != 'CA_TRIGGER_QA_QC_VOLUME' &&
                    featureText.FeatureConfigurationCode != 'CA_DISPLAY_CLEANING_ALERT_AFTER_BEACON_SCAN' &&
                    featureText.FeatureConfigurationCode != 'QC_DISPLAY_AFTER_BEACON_SCAN' &&
                    featureText.FeatureConfigurationCode != 'INS_COMPLETION_CRITERIA_BEACON_SCANNING'
                  "
                >
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <input
                      (keypress)="keyPressCheck($event)"
                      type="number"
                      id="text_{{ featureText.FeatureConfigurationId }}"
                      name="text_{{ featureText.FeatureConfigurationId }}"
                      min="1"
                      max="99999"
                      maxlength="5"
                      class="form-control"
                      placeholder="Time in minute"
                      [(ngModel)]="featureText.FeatureConfigurationValue"
                      #FeatureConfigurationValue="ngModel"
                      required
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    />

                    <div
                      *ngIf="
                        FeatureConfigurationValue.errors &&
                        (FeatureConfigurationValue.dirty || FeatureConfigurationValue.touched)
                      "
                      class="alert alert-danger"
                    >
                      <div [hidden]="!FeatureConfigurationValue.errors.required" class="text-danger">
                        Configuration value is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4" style="align-content: center; display: flex; flex-wrap: wrap">
                    <span>(Minute)</span>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-lg-12 col-sm-12"
                  *ngIf="featureText.FeatureConfigurationCode == 'CA_TRIGGER_QA_QC_VOLUME'"
                >
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <input
                      (keypress)="keyPressCheck($event)"
                      type="number"
                      id="text_{{ featureText.FeatureConfigurationId }}"
                      name="text_{{ featureText.FeatureConfigurationId }}"
                      min="1"
                      max="99999"
                      class="form-control"
                      placeholder="Enter Count"
                      [(ngModel)]="featureText.FeatureConfigurationValue"
                      #FeatureConfigurationValue="ngModel"
                      required
                    />
                    <div
                      *ngIf="
                        FeatureConfigurationValue.errors &&
                        (FeatureConfigurationValue.dirty || FeatureConfigurationValue.touched)
                      "
                      class="alert alert-danger"
                    >
                      <div [hidden]="!FeatureConfigurationValue.errors.required" class="text-danger">
                        Configuration value is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-lg-12 col-sm-12"
                  *ngIf="featureText.FeatureConfigurationCode == 'CA_DISPLAY_CLEANING_ALERT_AFTER_BEACON_SCAN'"
                >
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <input
                      (keypress)="keyPressCheck($event)"
                      type="number"
                      id="text_{{ featureText.FeatureConfigurationId }}"
                      name="text_{{ featureText.FeatureConfigurationId }}"
                      min="1"
                      max="99999"
                      class="form-control"
                      placeholder="Time in minute"
                      [(ngModel)]="featureText.FeatureConfigurationValue"
                      #FeatureConfigurationValue="ngModel"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4" style="align-content: center; display: flex; flex-wrap: wrap">
                    <span>(Minute)</span>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-lg-12 col-sm-12"
                  *ngIf="featureText.FeatureConfigurationCode == 'QC_DISPLAY_AFTER_BEACON_SCAN'"
                >
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <input
                      (keypress)="keyPressCheck($event)"
                      type="number"
                      id="text_{{ featureText.FeatureConfigurationId }}"
                      name="text_{{ featureText.FeatureConfigurationId }}"
                      min="1"
                      max="99999"
                      class="form-control"
                      placeholder="Time in minute"
                      [(ngModel)]="featureText.FeatureConfigurationValue"
                      #FeatureConfigurationValue="ngModel"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4" style="align-content: center; display: flex; flex-wrap: wrap">
                    <span>(Minute)</span>
                  </div>
                </div>

                <div
                  class="col-lg-12 col-lg-12 col-sm-12"
                  *ngIf="featureText.FeatureConfigurationCode == 'INS_COMPLETION_CRITERIA_BEACON_SCANNING'"
                >
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <input
                      (keypress)="keyPressCheck($event)"
                      type="number"
                      id="text_{{ featureText.FeatureConfigurationId }}"
                      name="text_{{ featureText.FeatureConfigurationId }}"
                      min="1"
                      max="99999"
                      class="form-control"
                      placeholder="Time in minute"
                      [(ngModel)]="featureText.FeatureConfigurationValue"
                      #FeatureConfigurationValue="ngModel"
                    />
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4" style="align-content: center; display: flex; flex-wrap: wrap">
                    <span>(Minute)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="col-md-6 form-group chkbox1" *ngIf="venue.VenueId">
            <div>
              <div>
                <mat-checkbox [(ngModel)]="venue.IsActive" name="isActive"> Is Active</mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type1 pull-right btn-cancel"
              (click)="clearFormData(VenueForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!venue.VenueId"
              class="btn btn-type btn-save"
              [disabled]="
                !VenueForm.form.valid ||
                isReqComplete ||
                !venue.Timezone ||
                !venue.Subscription.SubscriptionId ||
                !venue.Corporation.CorporationId ||
                !venue.BeaconManufacturer ||
                !venue.Subscription.ExpiryDate ||
                !venue.VenueType.VenueTypeId
              "
              (click)="addVenue(VenueForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="venue.VenueId"
              class="btn btn-type btn-save"
              [disabled]="
                !VenueForm.form.valid ||
                isReqComplete ||
                !venue.Timezone ||
                !venue.Subscription.SubscriptionId ||
                !venue.Corporation.CorporationId ||
                !venue.BeaconManufacturer ||
                !venue.Subscription.ExpiryDate ||
                !venue.VenueType.VenueTypeId ||
                !(TraxPermission.VENUE_EDIT | canAccess)
              "
              (click)="updateVenue(VenueForm)"
            /><!--(isScoreFactorShow && inspectionScoreFactorsIds.length === 0)-->
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<ng-template
  #itemTemplate
  let-item="item"
  let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange"
>
  <div class="form-inline row-item" style="display: inline-flex">
    <i
      *ngIf="item.children"
      (click)="onCollapseExpand()"
      aria-hidden="true"
      class="fa"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed"
    ></i>
    <div class="form-check">
      <mat-checkbox
        class="form-check-input"
        [(ngModel)]="item.checked"
        (change)="onCheckedChange()"
        [disabled]="item.disabled"
        [indeterminate]="item.indeterminate"
      >
        {{ item.text }}</mat-checkbox
      >
    </div>
  </div>
</ng-template>
