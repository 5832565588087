import { DatePipe } from '@angular/common';
import { Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { Configuration } from 'src/app/app.constants';
import { StateMaintain } from 'src/app/core/models/common.model';
import { TraxPermission } from 'src/app/core/models/trax-permission.model';
import { User } from 'src/app/core/models/user.model';
import { AuthState } from 'src/app/core/services/auth.service';
import { SubscriptionService } from 'src/app/features/usermanagement/subscription/components/subscription.http.service';
import { setUserData } from 'src/app/root-store/user-data/user-action';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { LogoService } from 'src/app/shared/directives/logo.service';
import { CorporationService } from '../../../corporationmanagement/corporation/components/corporation.http.service';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { ServiceProviderService } from '../../../serviceprovider/components/serviceprovider.http.service';
import {
  FeatureConfiguration,
  Module,
  ModuleFeature,
  ModuleFeatureType,
  ServiceProviderModule,
} from '../../../serviceprovider/serviceprovider.module';
import { SubscriptionModule } from '../../../usermanagement/subscription/subscription.module';
import { SurveyQuestionResponseConfigService } from '../../../venuesurveyconfigurations/surveyquestionresponseconfiguration/components/surveyquestionresponseconfiguration.http.service';
import { SurveyQuestionResponseType } from '../../../venuesurveyconfigurations/surveyquestionresponseconfiguration/surveyquestionresponseconfiguration.module';
import { Pin, WorkorderPriority, WorkorderSeverity } from '../../../workordermanagement/workorder/workorder.module';
import { CityModule } from '../../city/city.module';
import { CityService } from '../../city/components/city.http.service';
import { CountryService } from '../../country/components/country.http.service';
import { CountryModule } from '../../country/country.module';
import { StateService } from '../../state/components/state.http.service';
import { StateModule } from '../../state/state.module';
import {
  AuthenticationType,
  AuthenticationTypeKeys,
  ClientLogo,
  CMMSIntegrationType,
  CMMSIntegrationTypeKey,
  RelVenueAuthentication,
  RelVenueMapIntegrationTypeUrlModule,
  RelVenuePowerBI,
  UserAssignmentRuleModule,
  VenueModule,
  VenueType,
} from '../venue.module';
import { VenueService } from './venue.http.service';

@Injectable({
  providedIn: 'root',
})
export class config extends TreeviewConfig {
  hasAllCheckBox = true;
  hasFilter = false;
  hasCollapseExpand = true;
  maxHeight = 400;
}

@Component({
  selector: 'venue.component',
  templateUrl: 'venue.component.html',
  styleUrls: ['./venue.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    VenueService,
    UtilitiesService,
    SubscriptionService,
    CorporationService,
    CountryService,
    StateService,
    CityService,
    DatePipe,
    SurveyQuestionResponseConfigService,
    ServiceProviderService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: config },
  ],
})
export class VenueComponent implements OnInit {
  TraxPermission = TraxPermission;
  options: FormGroup;

  @ViewChild('fileInput') logoFileInputVariable: any;
  @ViewChild('geojsonFile') fileInputVariable: any;
  @ViewChild('myScrollspy') myScrollspy: ClassDecorator;
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;

  public currentUser: User;

  public venues: VenueModule[] = [];
  public venue: VenueModule = new VenueModule();

  public authenticationTypes: AuthenticationType[] = [];
  public authenticationTypeKeys: AuthenticationTypeKeys[] = [];

  public relVenueAuthentication: RelVenueAuthentication[] = [];
  public subscriptions: SubscriptionModule[] = [];

  public pins: Pin[] = [];
  public priorities: WorkorderPriority[] = [];
  public severities: WorkorderSeverity[] = [];

  public Corporations: CorporationModule[] = [];

  public countries: CountryModule[] = [];
  public states: StateModule[] = [];
  public cities: CityModule[] = [];

  public CmmsIntegrationTypes: CMMSIntegrationType[] = [];
  public CmmsIntegrationTypeKey: CMMSIntegrationTypeKey = new CMMSIntegrationTypeKey();

  public Module: Module = new Module();
  public Modules: Module[] = [];
  public TodayDate = new Date();
  public clientLogo: ClientLogo = new ClientLogo();

  public venueTypes: VenueType[] = [];
  public surveyQuestionResponseTypes: SurveyQuestionResponseType[] = [];
  public page = 1;
  public size = 10;
  public count = 0;
  public pageSizes: any[] = [];
  public searchString = '';

  public timeZones: any[];
  public isReqComplete = false;
  public authentcationkey: any;

  public listviewtypes: any[];
  public viewType: any = null;

  public file: File = null;
  public isSysAdmin: boolean;

  public IsPassive = false;
  public isTime = false;
  public isChecked = false;

  public isScanTime = false;
  public isCompletionTime = false;

  public isSTime = false;
  public isCTime = false;

  public map_for = false;
  // navActive = false;
  // activeNavigationUrls: any;
  // activeNavigationName: string;
  public currentSection = 'Emergency';

  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;
  public serviceProviderExpiryDate: any;

  public beaconManufacturers: any[] = [
    { idfield: 1, textfield: 'Estimote' },
    { idfield: 2, textfield: 'Kontakt' },
    { idfield: 3, textfield: 'Mist' },
  ];

  public thresholdResets: any[] = [
    { idfield: 1, textfield: 'Daily' },
    { idfield: 2, textfield: 'Cleaning Completion' },
    { idfield: 2, textfield: 'None' },
  ];

  public feedbackResets: any[] = [
    { idfield: 1, textfield: 'Daily' },
    { idfield: 2, textfield: 'Cleaning Completion' },
    { idfield: 2, textfield: 'None' },
  ];

  public corporationNameForm: string;
  public searchTerm: string;
  public stateDisabled = true;
  public cityDisabled = true;

  public ShiftStaffSchedule = '';
  public LocationUserAssignment = '';
  public EitherOneOrThese = '';

  public isShiftStaffSchedule = false;
  public isLocationUserAssignment = false;
  public isEitherOneOrThese = false;

  public OnDuty: number;
  public LocationStaffGender: number;

  // public isClientadmin = false;
  public pageState: StateMaintain = {
    pageName: 'venueForm',
    page: this.page,
    size: this.size,
    viewType: this.viewType,
  };

  public items: TreeviewItem[];
  public rows: string[];
  public config = {};
  public modules: Module[] = [];

  public isAllFeatureConfigurationChecked = false;
  public isCheckBoxVisible = false;

  public featureConfigText: FeatureConfiguration[] = [];
  // isScoreFactorShow = false;
  optradio1: any;
  logoFile: File = null;
  venueDefaultLogo = 'assets/uploadImage.png';
  venueLogoSrc: any = null;
  mapIntegrationTypeCode: string;

  isTouchlessFeedbackEnable = false;
  public TouchlessFeedback: number;
  public TouchlessFeedbackReason: number;
  ConsiderNonCompletedSurveyYes: number;
  ConsiderNonCompletedSurveyNo: number;
  isConsiderNonCompletedSurveyYes = false;
  isConsiderNonCompletedSurveyNo = false;
  isFlightDataEnabled: boolean;

  constructor(
    private datePipe: DatePipe,
    private authState: AuthState,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private subscriptionService: SubscriptionService,
    private venueService: VenueService,
    private corporationService: CorporationService,
    private countryService: CountryService,
    private stateService: StateService,
    private cityService: CityService,
    private configuration: Configuration,
    private logoService: LogoService,
    private surveyQueResponseConfigService: SurveyQuestionResponseConfigService,
    private formBuilder: FormBuilder,
    private serviceprovidersService: ServiceProviderService,
    private readonly store: Store,
  ) {
    this.currentUser = this.authState.getCurrentUser();

    this.venue.WorkorderPriority = new WorkorderPriority();
    this.venue.WorkorderSeverity = new WorkorderSeverity();
    this.venue.Pin = new Pin();

    this.venue.Country = new CountryModule();
    this.venue.State = new StateModule();
    this.venue.City = new CityModule();

    this.venue.CmmsIntegrationType = new CMMSIntegrationType();
    this.venue.CmmsIntegrationTypeKey = new CMMSIntegrationTypeKey();

    this.venue.Corporation = new CorporationModule();
    this.venue.Subscription = new SubscriptionModule();
    this.venue.UserAssignmentRule = new UserAssignmentRuleModule();
    this.venue.RelVenuePowerBI = new RelVenuePowerBI();

    this.venue.AuthenticationType = new AuthenticationType();
    this.venue.AuthenticationType.AuthenticationTypeKey = new AuthenticationTypeKeys();

    this.Module = new Module();
    this.Module.ModuleFeature = new ModuleFeature();
    this.Module.ModuleFeature.ModuleFeatureType = new ModuleFeatureType();
    const featureConfig: FeatureConfiguration[] = [];
    this.Module.ModuleFeature.ModuleFeatureType.FeatureConfigurations = featureConfig;
    // this.venue.InspectionScoreFactor = new InspectionScoreFactor();
    this.venue.VenueType = new VenueType();

    const mapConfig: RelVenueMapIntegrationTypeUrlModule[] = [];
    this.venue.RelVenueMapIntegrationTypeUrls = mapConfig;

    this.isSysAdmin = this.currentUser.UserApplicationLevel == 'ADMIN';
    // this.isClientadmin = this.currentUser.UserRole.RoleCode == 'clientadmin';

    this.options = this.formBuilder.group({
      hideRequired: false,
      floatLabel: 'auto',
      optradio1: ['', []],
    });
    this.optradio1 = 'optradio1';
    this.config = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500,
    };
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;

    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;

    this.getPageState();

    this.getAllCorporations(this.searchString, -1, -1);
    this.getAllcountries('', -1, -1);
    // this.getAllInspectionScoreFactors(this.searchString, -1, -1);
    this.getAllSurveys();
  }

  public getPageState() {
    this.pageState = this.authState.retrievePageState(this.pageState);
    if (this.pageState.page != null && this.pageState.size != null && this.pageState.viewType != null) {
      this.page = this.pageState.page;
      this.size = this.pageState.size;
      this.viewType = this.pageState.viewType;
      this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
    }
  }

  /// this funcation used to get result as per page size change
  public venuePageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
  }

  public setDefaults() {
    if (!this.venue.InspectionScoreGoal || this.venue.InspectionScoreGoal === 0) {
      this.venue.InspectionScoreGoal = 80;
    }

    if (!this.venue.FeedbackRatingGoal || this.venue.FeedbackRatingGoal === 0) {
      this.venue.FeedbackRatingGoal = 80;
    }

    if (!this.venue.FlightGateLicensesCount) {
      this.venue.FlightGateLicensesCount = 0;
    }

    if (!this.venue.NumberOfInspectionsGoal || this.venue.InspectionScoreGoal === 0) {
      this.venue.NumberOfInspectionsGoal = 1;
    }

    if (!this.venue.ServiceTicketResponseTimeGoal) {
      this.venue.ServiceTicketResponseTimeGoal = 0;
    }

    if (!this.venue.NumberOfServiceTicketsGoal || this.venue.NumberOfServiceTicketsGoal === 0) {
      this.venue.NumberOfServiceTicketsGoal = 1;
    }
  }

  public setAddForm() {
    this.venueLogoSrc = null;
    this.logoFile = null;

    this.searchString = '';
    this.venues = null;
    this.count = 0;
    this.featureConfigText = [];

    this.getAllcountries('', -1, -1);
    this.getAllTimeZones();
    this.getAllAuthenticationtypes('', -1, -1);
    this.getAllSubscriptions('', -1, -1);
    // this.getAllInspectionScoreFactors(this.searchString, -1, -1);
    this.getAllVenueTypes();

    this.getAllPins();
    this.getAllPriorities();
    this.getAllSeverities();

    this.ShiftStaffSchedule = '';
    this.LocationUserAssignment = '';
    this.EitherOneOrThese = '';

    this.isShiftStaffSchedule = false;
    this.isLocationUserAssignment = false;
    this.isEitherOneOrThese = false;

    this.OnDuty = 0;
    this.LocationStaffGender = 0;

    this.IsPassive = false;
    this.isTime = false;
    this.isChecked = false;

    this.map_for = false;
    this.getAllCMMSIntegrationtypes(this.searchString, -1, -1);

    this.venue = new VenueModule();
    this.venue.AuthenticationType = new AuthenticationType();
    this.venue.Subscription = new SubscriptionModule();
    this.venue.WorkorderPriority = new WorkorderPriority();
    this.venue.WorkorderSeverity = new WorkorderSeverity();
    this.venue.Corporation = new CorporationModule();
    this.venue.Country = new CountryModule();
    this.venue.State = new StateModule();
    this.venue.City = new CityModule();
    this.venue.UserAssignmentRule = new UserAssignmentRuleModule();
    this.venue.CmmsIntegrationType = new CMMSIntegrationType();
    this.venue.CmmsIntegrationTypeKey = new CMMSIntegrationTypeKey();
    this.venue.RelVenuePowerBI = new RelVenuePowerBI();
    this.venue.AuthenticationType.AuthenticationTypeKey = new AuthenticationTypeKeys();
    this.venue.CmmsIntegrationTypeKey = new CMMSIntegrationTypeKey();
    this.venue.AuthenticationType = new AuthenticationType();
    this.venue.Pin = new Pin();
    this.venue.VenueType = new VenueType();
    this.isCheckBoxVisible = false;
    // this.venue.InspectionScoreFactor = new InspectionScoreFactor();
    if (this.authState.getStoredSPLicenseExpiryDate()) {
      this.venue.Subscription.ExpiryDate = this.authState.getStoredSPLicenseExpiryDate();
    }
    this.venue.ConsiderNeutralSurvey = 'NONE';
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      const element = document.getElementById('VenueName');
      element.focus();
    }, 1000);
    this.isConsiderNonCompletedSurveyNo = true;
    this.isConsiderNonCompletedSurveyYes = false;
    this.setDefaults();
  }

  public setUpdateForm() {
    this.searchString = '';
    this.venues = null;
    this.count = 0;
    this.isCheckBoxVisible = false;

    this.setDefaults();

    this.getAllTimeZones();
    this.getAllAuthenticationtypes('', -1, -1);
    this.getAllSubscriptions('', -1, -1);
    this.getAllPins();
    this.getAllPriorities();
    this.getAllSeverities();
    this.getAllVenueTypes();
    this.getAllCMMSIntegrationtypes(this.searchString, -1, -1);

    // this.getAllInspectionScoreFactors(this.searchString, -1, -1);
    // this.getCorporationById(this.authState.getStoredContextCorporationId());

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  public clearFormData(venueForm: NgForm) {
    this.venue = new VenueModule();
    this.venue.RelVenuePowerBI = new RelVenuePowerBI();
    this.venue.Subscription = new SubscriptionModule();
    this.venue.Corporation = new CorporationModule();
    this.venue.Country = new CountryModule();
    this.venue.State = new StateModule();
    this.venue.City = new CityModule();
    this.venue.CmmsIntegrationType = new CMMSIntegrationType();
    this.venue.AuthenticationType = new AuthenticationType();
    this.venue.WorkorderPriority = new WorkorderPriority();
    this.venue.WorkorderSeverity = new WorkorderSeverity();
    this.venue.Pin = new Pin();
    this.venue.RelVenuePowerBI = new RelVenuePowerBI();
    // this.venue.InspectionScoreFactor = new InspectionScoreFactor();
    this.venue.VenueType = new VenueType();
    venueForm.reset();

    this.venueLogoSrc = null;
    this.searchString = '';
    this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
    this.relVenueAuthentication = [];
    // this.getAllSubscriptions('', -1, -1);
    this.fileReset();

    this.IsPassive = false;
    this.isTime = false;
    this.map_for = false;
    this.currentSection = 'BasicInfo';

    this.items = [];
    this.Modules = [];
    this.isCheckBoxVisible = false;
    this.mapIntegrationTypeCode = '';
    this.isTouchlessFeedbackEnable = false;
    // this.inspectionScoreFactorsIds = [];
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
  }

  public venuesSearch(searchString: string) {
    this.page = 1;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllVenues(searchString, 1, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.venues != null && this.venues.length > 0;
  }

  public showHide(model: AuthenticationTypeKeys) {
    model.isVisible = !model.isVisible;
  }

  public showHideIntegration(model: CMMSIntegrationTypeKey) {
    model.isVisible = !model.isVisible;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState.page = this.page;
    this.pageState.size = this.size;
    this.pageState.viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllVenues(this.searchString, this.page, this.size, this.viewType);
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      genericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };
    this.loaderService.display(true);
    this.venueService.GetVenueList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;
        if (this.venues.length < 0 && this.venues[0].Count / this.utilitiesService.initialPageSize < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'venueService getAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.venues[0] ? this.venues[0].Count : 0),
    );
  }

  public getVenueById(VenueId: number) {
    if (this.authState.canAccess(TraxPermission.VENUE_VIEW)) {
      this.ShiftStaffSchedule = '';
      this.LocationUserAssignment = '';
      this.EitherOneOrThese = '';
      this.isShiftStaffSchedule = false;
      this.isLocationUserAssignment = false;
      this.isEitherOneOrThese = false;

      this.loaderService.display(true);

      this.getAllAuthenticationtypes(this.searchString, -1, -1);
      this.getAllCMMSIntegrationtypes(this.searchString, -1, -1);
      this.fileReset();

      const CPvenue = new VenueModule();
      CPvenue.VenueCPId = this.authState.AESEncryptText(VenueId);

      this.venueService.GetSingle(CPvenue).subscribe(
        // eslint-disable-next-line complexity
        (data) => {
          this.loaderService.display(false);
          this.venue = data;

          this.venue.IsBadgeEnable = this.venue.IsBadgeEnable ? true : false;
          this.venue.HideScheduleCA = this.venue.HideScheduleCA ? true : false;
          if (this.venue.AttachmentURL != null) {
            this.venueLogoSrc = this.venue.AttachmentURL;
          } else {
            this.venueDefaultLogo = 'assets/uploadImage.png';
          }

          if (this.venue.Subscription.ExpiryDate) {
            // this.venue.Subscription.ExpiryDate = this.datePipe.transform(this.venue.Subscription.ExpiryDate, 'yyyy-MM-dd');
            this.venue.Subscription.ExpiryDate = this.venue.Subscription.ExpiryDate.split('T')[0];
          }

          if (this.venue.AuthenticationType == null) {
            this.venue.AuthenticationType = new AuthenticationType();
          }

          if (this.venue.RelVenuePowerBI == null) {
            this.venue.RelVenuePowerBI = new RelVenuePowerBI();
          }

          this.relVenueAuthentication = this.venue.RelVenueAuthentication;

          if (this.venue.RelVenueAuthentication != null && this.venue.RelVenueAuthentication.length > 0) {
            this.venue.AuthenticationType.AuthenticationTypeId =
              this.venue.RelVenueAuthentication[0].AuthenticationType.AuthenticationTypeId;
          }

          if (this.venue.VenueMapFiles != null) {
            if (this.venue.VenueMapFiles.length > 0) {
              const filesArray = [];
              for (let i = 0; i < this.venue.VenueMapFiles.length; i++) {
                const index = i + 1;
                filesArray.push({ serialNumber: index, fileName: this.venue.VenueMapFiles[i].fileName });
              }
              this.venue.VenueMapFiles = filesArray;
            }
          }

          if (this.venue.UserAssignmentRule != null) {
            if (this.venue.UserAssignmentRule.ShiftStaffSchedule == 1) {
              this.ShiftStaffSchedule = '1';
              this.isShiftStaffSchedule = true;
            }
            if (this.venue.UserAssignmentRule.LocationUserAssignment == 1) {
              this.LocationUserAssignment = '2';
              this.isLocationUserAssignment = true;
            }
            if (this.venue.UserAssignmentRule.EitherOneOrThese == 1) {
              this.EitherOneOrThese = '3';
              this.isEitherOneOrThese = true;
            }
            if (this.venue.UserAssignmentRule.OnDuty == 1) {
              this.OnDuty = 1;
            }
            if (this.venue.UserAssignmentRule.LocationStaffGender == 1) {
              this.LocationStaffGender = 1;
            }
          }

          if (this.venue.ConsiderNeutralSurvey == 'NEUTRAL') {
            this.venue.ConsiderNeutralSurvey = 'NONE';
          }
          if (this.venue.ConsiderNonCompletedSurvey == 1) {
            this.isConsiderNonCompletedSurveyYes = true;
            this.isConsiderNonCompletedSurveyNo = false;
            this.ConsiderNonCompletedSurveyYes = 1;
          } else {
            this.isConsiderNonCompletedSurveyNo = true;
            this.isConsiderNonCompletedSurveyYes = false;
            this.ConsiderNonCompletedSurveyNo = 0;
          }

          if (this.venue.Country.CountryId) {
            this.getAllstatesByCountry(this.venue.Country.CountryId);
            if (this.venue.State.StateId) {
              this.getAllcitiesByState(this.venue.State.StateId);
            }
          }

          if (this.venue.Subscription.SubscriptionType == 'Enterprise') {
            this.map_for = true;
          } else {
            this.map_for = false;
          }

          if (this.venue.Subscription.SubscriptionType == 'Basic') {
            this.IsPassive = true;
          } else {
            this.isChecked = false;
            this.isTime = false;
          }

          if (this.venue.IsPassive == 1) {
            this.isTime = true;
            this.isChecked = true;
          } else {
            this.isChecked = false;
            this.isTime = false;
          }

          if (this.venue.MaxBeaconScanTime != null && this.venue.MaxBeaconScanTime > 0) {
            this.isScanTime = true;
            this.isSTime = true;
          } else {
            this.isScanTime = false;
            this.isSTime = false;
          }

          if (this.venue.MaxCleaningAlertCompletionTime != null && this.venue.MaxCleaningAlertCompletionTime > 0) {
            this.isCompletionTime = true;
            this.isCTime = true;
          } else {
            this.isCompletionTime = false;
            this.isCTime = false;
          }

          if (this.venue.CmmsIntegrationType == null) {
            this.venue.CmmsIntegrationType = new CMMSIntegrationType();
          }

          if (this.venue.RelVenueCmmsIntegrationTypeUrls && this.venue.RelVenueCmmsIntegrationTypeUrls.length > 0) {
            this.venue.CmmsIntegrationType.IntegrationTypeId =
              this.venue.RelVenueCmmsIntegrationTypeUrls[0].CmmsIntegrationType.IntegrationTypeId;

            for (let i = 0; i < this.venue.RelVenueCmmsIntegrationTypeUrls.length; i++) {
              if (
                this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.ShowEye != null
              ) {
                this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.isShowEye =
                  this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.ShowEye
                    ? true
                    : false;
              }

              if (
                this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey
                  .ControlDataType == 'CheckBox'
              ) {
                if (
                  this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey
                    .IntegrationTypeKeyUrl == '1'
                ) {
                  this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = 1;
                } else {
                  this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = 0;
                }
              }
            }
          }

          if (this.venue.RelVenueMapIntegrationTypeUrls && this.venue.RelVenueMapIntegrationTypeUrls.length > 0) {
            this.venue.VenueMapIntegrationTypeId =
              this.venue.RelVenueMapIntegrationTypeUrls[0].VenuemapIntegrationType.VenueMapIntegrationTypeId;

            this.mapIntegrationTypeCode =
              this.venue.RelVenueMapIntegrationTypeUrls[0].VenuemapIntegrationType.VenueMapIntegrationTypeCode;

            for (let i = 0; i < this.venue.RelVenueMapIntegrationTypeUrls.length; i++) {
              if (
                this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                  .ShowEye != null
              ) {
                this.venue.RelVenueMapIntegrationTypeUrls[
                  i
                ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.isShowEye = this.venue
                  .RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.ShowEye
                  ? true
                  : false;
              }

              if (
                this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                  .ControlDataType == 'CheckBox'
              ) {
                if (
                  this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                    .VenueMapIntegrationTypeKeyUrl == '1'
                ) {
                  this.venue.RelVenueMapIntegrationTypeUrls[
                    i
                  ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive = 1;
                } else {
                  this.venue.RelVenueMapIntegrationTypeUrls[
                    i
                  ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive = 0;
                }
              }
            }
          }

          if (this.venue.ThresholdReset == 'D') {
            this.venue.ThresholdReset = 'Daily';
          } else if (this.venue.ThresholdReset == 'C') {
            this.venue.ThresholdReset = 'Cleaning Completion';
          } else {
            this.venue.ThresholdReset = 'None';
          }

          if (this.venue.FeedbackReset == 'D') {
            this.venue.FeedbackReset = 'Daily';
          } else if (this.venue.FeedbackReset == 'C') {
            this.venue.FeedbackReset = 'Cleaning Completion';
          } else {
            this.venue.FeedbackReset = 'None';
          }

          if (this.venue.Corporation.CorporationId) {
            this.changeCorporation(this.venue.Corporation.CorporationId, false);
          }

          if (this.venue.VenueName == 'ATL') {
            if (
              this.venue != null &&
              this.venue.RelVenueMapIntegrationTypeUrls != null &&
              this.venue.RelVenueMapIntegrationTypeUrls.length > 0
            ) {
              for (let i = 0; i < this.venue.RelVenueMapIntegrationTypeUrls.length; i++) {
                if (
                  this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenueMapIntegrationTypeCode ==
                  'GOOGLE_MAP'
                ) {
                  if (
                    this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                      .VenueMapIntegrationTypeKeyName == 'API_KEY' &&
                    this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                      .VenueMapIntegrationTypeKeyUrl != null &&
                    this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                      .VenueMapIntegrationTypeKeyUrl != ''
                  ) {
                    this.authState.storedGoogleAPIKey(
                      this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
                        .VenueMapIntegrationTypeKeyUrl,
                    );
                  }
                }
              }
            }
          }

          if (this.venue.EnableTouchlessFeedback) {
            this.isTouchlessFeedbackEnable = true;
          }
        },
        (error) => {
          console.log(
            'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Venue', 'You do not have permission to view venue details.');
    }
  }

  public addVenue(VenueForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (VenueForm.status == 'INVALID' && VenueForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Venue', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.venue.Subscription.ExpiryDate = this.datePipe.transform(this.venue.Subscription.ExpiryDate, 'yyyy-MM-dd');
    if (this.serviceProviderExpiryDate && this.venue.Subscription.ExpiryDate > this.serviceProviderExpiryDate) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Venue',
        'Venue expiry date should not be greater than service provider license expiry date.',
      );
      return;
    }

    // check validation pattern of feature configuration value
    let obj = this.utilitiesService.checkFeatureConfigTimeValueValidation(this.featureConfigText, 'Venue');
    if (!obj) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.venue.EnableEmergencyAlert = this.venue.EnableEmergencyAlert ? 1 : 0;
    this.venue.Subscription.SubscriptionId = this.venue.Subscription.SubscriptionId;

    this.venue.VenueName = this.utilitiesService.removeInnerSpaces(this.venue.VenueName);
    if (this.venue.VenueName == null || this.venue.VenueName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    if (this.ShiftStaffSchedule == '' && this.LocationUserAssignment == '' && this.EitherOneOrThese == '') {
      this.utilitiesService.smmodal('Venue', 'Please select user assignment configuration.');
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    if (this.venue.PoweredBy) {
      this.venue.PoweredBy = this.utilitiesService.removeInnerSpaces(this.venue.PoweredBy);
      if (this.venue.PoweredBy == null || this.venue.PoweredBy == '') {
        this.venue.PoweredBy = '';
      }
    }

    this.venue.AddedBy = this.currentUser.UserId;
    this.venue.RelVenueAuthentication = this.relVenueAuthentication;

    // this.venue.IsCustodialQcEnable = this.venue.IsCustodialQcEnable ? 1 : 0;
    this.venue.IsMaintenanceQcEnable = this.venue.IsMaintenanceQcEnable ? 1 : 0;
    this.venue.IsBadgeEnable = this.venue.IsBadgeEnable ? 1 : 0;
    this.venue.HideScheduleCA = this.venue.HideScheduleCA ? 1 : 0;

    this.venue.Pin.PinId = this.venue.Pin.PinId;
    this.venue.WorkorderPriority.PriorityId = this.venue.WorkorderPriority.PriorityId;
    this.venue.WorkorderSeverity.SeverityId = this.venue.WorkorderSeverity.SeverityId;
    this.venue.Corporation.CorporationId = this.venue.Corporation.CorporationId;
    this.venue.VenueSurveyId = this.venue.VenueSurveyId;
    this.venue.FirstName = this.venue.FirstName;
    this.venue.LastName = this.venue.LastName;
    this.venue.Email = this.venue.Email;
    this.venue.MobileNumber = this.venue.MobileNumber;
    this.venue.AddressLineOne = this.venue.AddressLineOne;
    this.venue.Country.CountryId = this.venue.Country.CountryId;
    this.venue.State.StateId = this.venue.State.StateId;
    this.venue.City.CityId = this.venue.City.CityId;
    this.venue.ZipCode = this.venue.ZipCode;
    this.venue.IsPassive = this.venue.IsPassive ? 1 : 0;
    this.venue.MapForCleaningAlert = this.venue.MapForCleaningAlert ? 1 : 0;
    this.venue.MapForMaintenance = this.venue.MapForMaintenance ? 1 : 0;
    this.venue.EnableLocationStatus = this.venue.EnableLocationStatus ? 1 : 0;
    this.venue.EnableDigitalScreen = this.venue.EnableDigitalScreen ? 1 : 0;
    this.venue.VenueIataCode = this.venue.VenueIataCode === '' ? null : this.venue.VenueIataCode;

    if (this.venue.RelVenueCmmsIntegrationTypeUrls != null && this.venue.RelVenueCmmsIntegrationTypeUrls.length > 0) {
      for (let i = 0; i < this.venue.RelVenueCmmsIntegrationTypeUrls.length; i++) {
        if (
          this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.ControlDataType ==
          'CheckBox'
        ) {
          if (this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive == 1) {
            this.venue.RelVenueCmmsIntegrationTypeUrls[
              i
            ].CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyUrl = '1';

            this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = null;
          } else {
            this.venue.RelVenueCmmsIntegrationTypeUrls[
              i
            ].CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyUrl = '0';
            this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = null;
          }
        }
      }
    }
    this.venue.RelVenueCmmsIntegrationTypeUrls = this.venue.RelVenueCmmsIntegrationTypeUrls;

    if (this.venue.RelVenueMapIntegrationTypeUrls != null && this.venue.RelVenueMapIntegrationTypeUrls.length > 0) {
      for (let i = 0; i < this.venue.RelVenueMapIntegrationTypeUrls.length; i++) {
        if (
          this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
            .ControlDataType == 'CheckBox'
        ) {
          if (
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive ==
            1
          ) {
            this.venue.RelVenueMapIntegrationTypeUrls[
              i
            ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.VenueMapIntegrationTypeKeyUrl = '1';
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive =
              null;
          } else {
            this.venue.RelVenueMapIntegrationTypeUrls[
              i
            ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.VenueMapIntegrationTypeKeyUrl = '0';
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive =
              null;
          }

          if (this.mapIntegrationTypeCode == 'POINT_CONSULTING') {
            this.venue.RelVenueMapIntegrationTypeUrls[
              i
            ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.VenueMapIntegrationTypeKeyUrl = '1';
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive =
              null;
          }
        }
      }
    }
    this.venue.RelVenueMapIntegrationTypeUrls = this.venue.RelVenueMapIntegrationTypeUrls;

    this.venue.KontaktVenueId = this.venue.KontaktVenueId;
    this.venue.KontaktAPIKey = this.venue.KontaktAPIKey;

    const _userAssignmentRule: UserAssignmentRuleModule = new UserAssignmentRuleModule();
    if (this.ShiftStaffSchedule == '1') {
      _userAssignmentRule.ShiftStaffSchedule = 1;
      _userAssignmentRule.LocationUserAssignment = 0;
      _userAssignmentRule.EitherOneOrThese = 0;
    } else if (this.LocationUserAssignment == '2') {
      _userAssignmentRule.LocationUserAssignment = 1;
      _userAssignmentRule.ShiftStaffSchedule = 0;
      _userAssignmentRule.EitherOneOrThese = 0;
    } else if (this.EitherOneOrThese == '3') {
      _userAssignmentRule.EitherOneOrThese = 1;
      _userAssignmentRule.ShiftStaffSchedule = 0;
      _userAssignmentRule.LocationUserAssignment = 0;
    }
    _userAssignmentRule.OnDuty = this.OnDuty ? 1 : 0;
    _userAssignmentRule.LocationStaffGender = this.LocationStaffGender ? 1 : 0;

    this.venue.UserAssignmentRule = _userAssignmentRule;
    this.venue.RelVenuePowerBI.CleaningReportURL = this.venue.RelVenuePowerBI.CleaningReportURL;
    this.venue.RelVenuePowerBI.PredictiveReportURL = this.venue.RelVenuePowerBI.PredictiveReportURL;
    this.venue.RelVenuePowerBI.SurveyReportURL = this.venue.RelVenuePowerBI.SurveyReportURL;
    this.venue.RelVenuePowerBI.ThroughputReportURL = this.venue.RelVenuePowerBI.ThroughputReportURL;
    this.venue.RelVenuePowerBI.InspectionSummaryReportURL = this.venue.RelVenuePowerBI.InspectionSummaryReportURL;
    this.venue.RelVenuePowerBI.ZurnFlushValveSummaryReportURL =
      this.venue.RelVenuePowerBI.ZurnFlushValveSummaryReportURL;

    if (this.venue.ThresholdReset == 'Daily') {
      this.venue.ThresholdReset = 'D';
    } else if (this.venue.ThresholdReset == 'Cleaning Completion') {
      this.venue.ThresholdReset = 'C';
    } else {
      this.venue.ThresholdReset = 'N';
    }

    if (this.venue.FeedbackReset == 'Daily') {
      this.venue.FeedbackReset = 'D';
    } else if (this.venue.FeedbackReset == 'Cleaning Completion') {
      this.venue.FeedbackReset = 'C';
    } else {
      this.venue.FeedbackReset = 'N';
    }

    this.venue.CreateAlertOnThresholdBreach = this.venue.CreateAlertOnThresholdBreach ? 1 : 0;

    let payload: Module[] = [];
    if (this.treeviewComponent && this.treeviewComponent.items != null && this.treeviewComponent.items.length > 0) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleFeatures = [];

          it.children.forEach(function (mf) {
            if (mf.checked || mf.checked == undefined) {
              let mFeature = new ModuleFeature();
              mFeature.ModuleFeatureId = mf.value;
              mFeature.ModuleFeatureTypes = [];

              mf.children.forEach(function (mft) {
                if (mft.checked || mft.checked == undefined) {
                  let mFeatureType = new ModuleFeatureType();
                  mFeatureType.FeatureTypeCode = mft.value;
                  mFeatureType.FeatureConfigurations = [];

                  mft.children.forEach(function (fc) {
                    if (fc.checked || fc.checked == undefined) {
                      let fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId = fc.value;
                      fConfig.IsActive = 1;

                      mFeatureType.FeatureConfigurations.push(fConfig);
                    }
                  });
                  mFeature.ModuleFeatureTypes.push(mFeatureType);
                }
              });
              module.ModuleFeatures.push(mFeature);
            }
          });
          payload.push(module);
        }
      });
    }

    if (payload && payload.length > 0) {
      if (this.featureConfigText != null && this.featureConfigText.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          for (let j = 0; j < payload[i].ModuleFeatures.length; j++) {
            for (let k = 0; k < payload[i].ModuleFeatures[j].ModuleFeatureTypes.length; k++) {
              for (
                let l = 0;
                l < payload[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations.length;
                l++
              ) {
                for (let m = 0; m < this.featureConfigText.length; m++) {
                  if (
                    payload[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                      .FeatureConfigurationId == this.featureConfigText[m].FeatureConfigurationId
                  ) {
                    payload[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[
                      l
                    ].FeatureConfigurationValue = this.featureConfigText[m].FeatureConfigurationValue;
                  }
                }
              }
            }
          }
        }
      }
    }

    this.venue.Modules = payload;
    // console.log(JSON.stringify(this.venue));

    this.venue.EnableTouchlessFeedback = this.venue.EnableTouchlessFeedback ? 1 : 0;
    this.venue.EnableTouchlessFeedbackComment = this.venue.EnableTouchlessFeedbackComment ? 1 : 0;
    this.venue.EnableTouchlessFeedbackReason = this.venue.EnableTouchlessFeedbackReason ? 1 : 0;
    if (this.venue.ConsiderNeutralSurvey == 'NONE') {
      this.venue.ConsiderNeutralSurvey = 'NEUTRAL';
    }

    this.venue.IsEquipmentPMEventEnabled = this.venue.IsEquipmentPMEventEnabled ? 1 : 0;
    if (!this.venue.IsEquipmentPMEventEnabled) {
      this.venue.EquimentPMConfigureTime = null;
    }

    this.venue.IsProjectWorkEventEnabled = this.venue.IsProjectWorkEventEnabled ? 1 : 0;
    if (!this.venue.IsProjectWorkEventEnabled) {
      this.venue.ProjectWorkConfigureTime = null;
    }

    this.venue.IsNotificationForNegativeFeedbackEnabled = this.venue.IsNotificationForNegativeFeedbackEnabled ? 1 : 0;
    if (!this.venue.IsNotificationForNegativeFeedbackEnabled) {
      this.venue.NumberOfNegativeFeedbacksForNotification = null;
    }

    this.venueService.Add(this.utilitiesService.stripScript(this.venue)).subscribe(
      (data) => {
        if (this.logoFile) {
          this.venueService.AddVenueLogo(this.logoFile, data.id);
        }

        this.page = 1;
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.snackBarWithAutoDismiss('Venue', data.Message);
        this.clearFormData(VenueForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('venueService addVenue Call Failed. Status:' + error.status + 'Status Text' + error.statusText);
      },
    );
  }

  public updateVenue(VenueForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (VenueForm.status == 'INVALID' && VenueForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Venue', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.venue.Subscription.ExpiryDate = this.datePipe.transform(this.venue.Subscription.ExpiryDate, 'yyyy-MM-dd');
    if (this.serviceProviderExpiryDate && this.venue.Subscription.ExpiryDate > this.serviceProviderExpiryDate) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Venue',
        'Venue expiry date should not be greater than service provider license expiry date.',
      );
      return;
    }

    // check validation pattern of feature configuration value
    let obj = this.utilitiesService.checkFeatureConfigTimeValueValidation(this.featureConfigText, 'Venue');
    if (!obj) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.venue.EnableEmergencyAlert = this.venue.EnableEmergencyAlert ? 1 : 0;
    this.venue.Subscription.SubscriptionId = this.venue.Subscription.SubscriptionId;
    this.venue.RelVenueSubscriptionId = this.venue.RelVenueSubscriptionId;

    this.venue.VenueName = this.utilitiesService.removeInnerSpaces(this.venue.VenueName);
    if (this.venue.VenueName == null || this.venue.VenueName == '') {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    if (this.ShiftStaffSchedule == '' && this.LocationUserAssignment == '' && this.EitherOneOrThese == '') {
      this.utilitiesService.smmodal('Venue', 'Please select user assignment configuration.');
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    if (this.venue.PoweredBy) {
      this.venue.PoweredBy = this.utilitiesService.removeInnerSpaces(this.venue.PoweredBy);
      if (this.venue.PoweredBy == null || this.venue.PoweredBy == '') {
        this.venue.PoweredBy = '';
      }
    }

    this.venue.ModifiedBy = this.currentUser.UserId;
    this.venue.IsActive = this.venue.IsActive ? 1 : 0;
    this.venue.RelVenueAuthentication = this.relVenueAuthentication;
    // this.venue.IsCustodialQcEnable = this.venue.IsCustodialQcEnable ? 1 : 0;
    this.venue.IsMaintenanceQcEnable = this.venue.IsMaintenanceQcEnable ? 1 : 0;
    this.venue.IsBadgeEnable = this.venue.IsBadgeEnable ? 1 : 0;
    this.venue.HideScheduleCA = this.venue.HideScheduleCA ? 1 : 0;
    this.venue.Corporation.CorporationId = this.venue.Corporation.CorporationId;

    this.venue.Pin.PinId = this.venue.Pin.PinId;
    this.venue.WorkorderPriority.PriorityId = this.venue.WorkorderPriority.PriorityId;
    this.venue.WorkorderSeverity.SeverityId = this.venue.WorkorderSeverity.SeverityId;
    this.venue.VenueSurveyId = this.venue.VenueSurveyId;
    this.venue.FirstName = this.venue.FirstName;
    this.venue.LastName = this.venue.LastName;
    this.venue.Email = this.venue.Email;
    this.venue.MobileNumber = this.venue.MobileNumber;
    this.venue.AddressLineOne = this.venue.AddressLineOne;
    this.venue.Country.CountryId = this.venue.Country.CountryId;
    this.venue.State.StateId = this.venue.State.StateId;
    this.venue.City.CityId = this.venue.City.CityId;
    this.venue.ZipCode = this.venue.ZipCode;
    this.venue.IsPassive = this.venue.IsPassive ? 1 : 0;
    this.venue.MapForCleaningAlert = this.venue.MapForCleaningAlert ? 1 : 0;
    this.venue.MapForMaintenance = this.venue.MapForMaintenance ? 1 : 0;
    this.venue.EnableLocationStatus = this.venue.EnableLocationStatus ? 1 : 0;
    this.venue.EnableDigitalScreen = this.venue.EnableDigitalScreen ? 1 : 0;
    // this.venue.MaxBeaconScanTime = this.venue.MaxBeaconScanTime;
    // this.venue.MaxCleaningAlertCompletionTime = this.venue.MaxCleaningAlertCompletionTime;

    if (this.venue.RelVenueCmmsIntegrationTypeUrls != null && this.venue.RelVenueCmmsIntegrationTypeUrls.length > 0) {
      for (let i = 0; i < this.venue.RelVenueCmmsIntegrationTypeUrls.length; i++) {
        if (
          this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.ControlDataType ==
          'CheckBox'
        ) {
          if (this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive == 1) {
            this.venue.RelVenueCmmsIntegrationTypeUrls[
              i
            ].CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyUrl = '1';
            this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = null;
          } else {
            this.venue.RelVenueCmmsIntegrationTypeUrls[
              i
            ].CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyUrl = '0';
            this.venue.RelVenueCmmsIntegrationTypeUrls[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = null;
          }
        }
      }
    }
    this.venue.RelVenueCmmsIntegrationTypeUrls = this.venue.RelVenueCmmsIntegrationTypeUrls;

    if (this.venue.RelVenueMapIntegrationTypeUrls != null && this.venue.RelVenueMapIntegrationTypeUrls.length > 0) {
      for (let i = 0; i < this.venue.RelVenueMapIntegrationTypeUrls.length; i++) {
        if (
          this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey
            .ControlDataType == 'CheckBox'
        ) {
          if (
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive ==
            1
          ) {
            this.venue.RelVenueMapIntegrationTypeUrls[
              i
            ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.VenueMapIntegrationTypeKeyUrl = '1';
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive =
              null;
          } else {
            this.venue.RelVenueMapIntegrationTypeUrls[
              i
            ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.VenueMapIntegrationTypeKeyUrl = '0';
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive =
              null;
          }

          if (this.mapIntegrationTypeCode == 'POINT_CONSULTING') {
            this.venue.RelVenueMapIntegrationTypeUrls[
              i
            ].VenuemapIntegrationType.VenuemapIntegrationTypeKey.VenueMapIntegrationTypeKeyUrl = '1';
            this.venue.RelVenueMapIntegrationTypeUrls[i].VenuemapIntegrationType.VenuemapIntegrationTypeKey.IsActive =
              null;
          }
        }
      }
    }
    this.venue.RelVenueMapIntegrationTypeUrls = this.venue.RelVenueMapIntegrationTypeUrls;

    this.venue.KontaktVenueId = this.venue.KontaktVenueId;
    this.venue.KontaktAPIKey = this.venue.KontaktAPIKey;

    const _userAssignmentRule: UserAssignmentRuleModule = new UserAssignmentRuleModule();
    if (this.ShiftStaffSchedule == '1') {
      _userAssignmentRule.ShiftStaffSchedule = 1;
      _userAssignmentRule.LocationUserAssignment = 0;
      _userAssignmentRule.EitherOneOrThese = 0;
    } else if (this.LocationUserAssignment == '2') {
      _userAssignmentRule.LocationUserAssignment = 1;
      _userAssignmentRule.ShiftStaffSchedule = 0;
      _userAssignmentRule.EitherOneOrThese = 0;
    } else if (this.EitherOneOrThese == '3') {
      _userAssignmentRule.EitherOneOrThese = 1;
      _userAssignmentRule.ShiftStaffSchedule = 0;
      _userAssignmentRule.LocationUserAssignment = 0;
    }
    _userAssignmentRule.OnDuty = this.OnDuty ? 1 : 0;
    _userAssignmentRule.LocationStaffGender = this.LocationStaffGender ? 1 : 0;

    this.venue.UserAssignmentRule = _userAssignmentRule;

    if (this.venue.Subscription.SubscriptionId) {
      const subscriptionObj = this.subscriptions.filter(
        (s) => s.SubscriptionId == this.venue.Subscription.SubscriptionId,
      );
      if (subscriptionObj[0]) {
        if (subscriptionObj[0].SubscriptionType == 'Basic') {
          this.authState.storedSubscriptionType(subscriptionObj[0].SubscriptionType);
        } else {
          this.authState.storedSubscriptionType(subscriptionObj[0].SubscriptionType);
        }
      }
    }

    this.venue.RelVenuePowerBI.CleaningReportURL = this.venue.RelVenuePowerBI.CleaningReportURL;
    this.venue.RelVenuePowerBI.PredictiveReportURL = this.venue.RelVenuePowerBI.PredictiveReportURL;
    this.venue.RelVenuePowerBI.SurveyReportURL = this.venue.RelVenuePowerBI.SurveyReportURL;
    this.venue.RelVenuePowerBI.ThroughputReportURL = this.venue.RelVenuePowerBI.ThroughputReportURL;
    this.venue.RelVenuePowerBI.InspectionSummaryReportURL = this.venue.RelVenuePowerBI.InspectionSummaryReportURL;
    this.venue.RelVenuePowerBI.ZurnFlushValveSummaryReportURL =
      this.venue.RelVenuePowerBI.ZurnFlushValveSummaryReportURL;

    if (this.venue.ThresholdReset == 'Daily') {
      this.venue.ThresholdReset = 'D';
    } else if (this.venue.ThresholdReset == 'Cleaning Completion') {
      this.venue.ThresholdReset = 'C';
    } else {
      this.venue.ThresholdReset = 'N';
    }

    if (this.venue.FeedbackReset == 'Daily') {
      this.venue.FeedbackReset = 'D';
    } else if (this.venue.FeedbackReset == 'Cleaning Completion') {
      this.venue.FeedbackReset = 'C';
    } else {
      this.venue.FeedbackReset = 'N';
    }

    this.venue.CreateAlertOnThresholdBreach = this.venue.CreateAlertOnThresholdBreach ? 1 : 0;

    let payload: Module[] = [];
    if (this.treeviewComponent && this.treeviewComponent.items != null && this.treeviewComponent.items.length > 0) {
      this.treeviewComponent.items.forEach((it) => {
        if (it.checked || it.checked == undefined) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleFeatures = [];

          it.children.forEach((mf) => {
            if (mf.checked || mf.checked == undefined) {
              let mFeature = new ModuleFeature();
              mFeature.ModuleFeatureId = mf.value;
              mFeature.ModuleFeatureTypes = [];

              mf.children.forEach((mft) => {
                if (mft.checked || mft.checked == undefined) {
                  let mFeatureType = new ModuleFeatureType();
                  mFeatureType.FeatureTypeCode = mft.value;
                  mFeatureType.FeatureConfigurations = [];

                  mft.children.forEach((fc) => {
                    if (fc.checked || fc.checked == undefined) {
                      let fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId = fc.value;
                      fConfig.IsActive = 1;

                      mFeatureType.FeatureConfigurations.push(fConfig);
                    }
                  });
                  mFeature.ModuleFeatureTypes.push(mFeatureType);
                }
              });
              module.ModuleFeatures.push(mFeature);
            }
          });
          payload.push(module);
        }
      });
    }

    if (payload && payload.length > 0) {
      if (this.featureConfigText != null && this.featureConfigText.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          for (let j = 0; j < payload[i].ModuleFeatures.length; j++) {
            for (let k = 0; k < payload[i].ModuleFeatures[j].ModuleFeatureTypes.length; k++) {
              for (
                let l = 0;
                l < payload[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations.length;
                l++
              ) {
                for (let m = 0; m < this.featureConfigText.length; m++) {
                  if (
                    payload[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                      .FeatureConfigurationId == this.featureConfigText[m].FeatureConfigurationId
                  ) {
                    payload[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[
                      l
                    ].FeatureConfigurationValue = this.featureConfigText[m].FeatureConfigurationValue;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.venue.Modules = payload;
    // console.log(JSON.stringify(this.venue));

    if (this.logoFile) {
      this.venueService.AddVenueLogo(this.logoFile, this.venue.VenueId);
    } else if (this.venueLogoSrc == null) {
      const CPvenue = new VenueModule();
      CPvenue.VenueCPId = this.authState.AESEncryptText(this.venue.VenueId);
      this.venueService.RemoveVenueLogo(CPvenue).subscribe((_res) => {
        // console.log(res);
      });
    }

    this.venue.EnableTouchlessFeedback = this.venue.EnableTouchlessFeedback ? 1 : 0;
    this.venue.EnableTouchlessFeedbackComment = this.venue.EnableTouchlessFeedbackComment ? 1 : 0;
    this.venue.EnableTouchlessFeedbackReason = this.venue.EnableTouchlessFeedbackReason ? 1 : 0;
    this.venue.VenueIataCode = this.venue.VenueIataCode === '' ? null : this.venue.VenueIataCode;

    this.TouchlessFeedback = this.venue.EnableTouchlessFeedback;
    this.TouchlessFeedbackReason = this.venue.EnableTouchlessFeedbackReason;
    if (this.venue.ConsiderNeutralSurvey == 'NONE') {
      this.venue.ConsiderNeutralSurvey = 'NEUTRAL';
    }

    // if (!this.isScoreFactorShow) {
    //     this.venue.InspectionScoreFactor.InspectionScoreFactorId = null;
    // }

    this.venue.IsEquipmentPMEventEnabled = this.venue.IsEquipmentPMEventEnabled ? 1 : 0;
    if (!this.venue.IsEquipmentPMEventEnabled) {
      this.venue.EquimentPMConfigureTime = null;
    }

    this.venue.IsProjectWorkEventEnabled = this.venue.IsProjectWorkEventEnabled ? 1 : 0;
    if (!this.venue.IsProjectWorkEventEnabled) {
      this.venue.ProjectWorkConfigureTime = null;
    }

    this.venue.IsNotificationForNegativeFeedbackEnabled = this.venue.IsNotificationForNegativeFeedbackEnabled ? 1 : 0;
    if (!this.venue.IsNotificationForNegativeFeedbackEnabled) {
      this.venue.NumberOfNegativeFeedbacksForNotification = null;
    }

    this.venueService.Update(this.utilitiesService.stripScript(this.venue)).subscribe(
      (data) => {
        const storedVenue = this.authState.getStoredContextVenueId();
        if (storedVenue == this.venue.VenueId) {
          this.getVenueFeatureConfigurations(this.venue.VenueId);

          setTimeout(() => {
            this.getMainLogo();
          }, 3000);
        }

        let ShiftStaffSchedule = 0;
        let LocationUserAssignment = 0;
        let EitherOneOrThese = 0;

        if (this.ShiftStaffSchedule == '1') {
          ShiftStaffSchedule = 1;
        } else {
          ShiftStaffSchedule = 0;
        }
        if (this.LocationUserAssignment == '2') {
          LocationUserAssignment = 1;
        } else {
          LocationUserAssignment = 0;
        }
        if (this.EitherOneOrThese == '3') {
          EitherOneOrThese = 1;
        } else {
          EitherOneOrThese = 0;
        }

        this.authState.storedShiftStaffSchedule(ShiftStaffSchedule);
        this.authState.storedLocationUserAssignment(LocationUserAssignment);
        this.authState.storedEitherOneOrThese(EitherOneOrThese);

        this.authState.storedEnableTouchlessFeedback(this.TouchlessFeedback);
        this.authState.storedEnableTouchlessFeedbackReason(this.TouchlessFeedbackReason);
        this.store.dispatch(setUserData({ user: { ...this.currentUser, lastUpdated: new Date() } }));

        if (this.venue.VenueId == this.authState.getStoredContextVenueId()) {
          this.configuration.VenueName = this.venue.VenueName;
          this.authState.storedVenueName(this.configuration.VenueName);
        }

        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.snackBarWithAutoDismiss('Venue', data.Message);
        this.clearFormData(VenueForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'venueService updateVenue Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllTimeZones() {
    this.venueService.GetAllTimeZones().subscribe(
      (data) => {
        this.timeZones = data;
      },
      (error) => {
        console.log(
          'venueService GetAllTimeZones Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public selectedzone($event: any) {
    this.venue.Timezone = $event;
    if ($event != null) {
      const datadiff = this.timeZones.find((value) => value.DisplayName == $event).Offset;
      this.venue.Timediff = datadiff;
    } else {
      this.venue.Timediff = null;
    }
  }

  public getAllAuthenticationtypes(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetAuthenticationtypeDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.authenticationTypes = data;
      },
      (error) => {
        console.log(
          'venueService getAllAuthenticationtypes Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public GetAllAuthenticationtypekeys(authenticationTypeId: any) {
    this.loaderService.display(true);
    if (authenticationTypeId != null) {
      const CPauthenticationtype = new AuthenticationType();
      CPauthenticationtype.AuthenticationTypeCPId = this.authState.AESEncryptText(authenticationTypeId);
      this.venueService.GetAuthenticationtypekeys(CPauthenticationtype).subscribe(
        (data) => {
          this.relVenueAuthentication = data;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'venueService GetAllAuthenticationtypes Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
    } else {
      this.authenticationTypeKeys = [];
    }
  }

  public changeAuthenticationtype($event: any) {
    this.venue.AuthenticationType.AuthenticationTypeId = $event;
    if (this.venue.AuthenticationType.AuthenticationTypeId != null) {
      this.GetAllAuthenticationtypekeys(this.venue.AuthenticationType.AuthenticationTypeId);
    } else {
      this.relVenueAuthentication = [];
      this.venue.AuthenticationType.AuthenticationTypeId = null;
    }
  }

  public getAllSubscriptions(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 1 };

    this.subscriptionService.GetSubscriptionDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.subscriptions = data;

        if (this.venue.Subscription != null && this.venue.Subscription.SubscriptionId > 0) {
          const obj = this.subscriptions.filter((s) => s.SubscriptionId == this.venue.Subscription.SubscriptionId);
          this.venue.Subscription.SubscriptionType = obj[0].SubscriptionType;
        }
      },
      (error) => {
        console.log(
          'SubscriptionService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllCorporations(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0, IsActive: 1 };

    this.corporationService.GetCorporationListForDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.Corporations = data;
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'corporationService getAllCorporations Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public changeCorporation($event: any, isCorporationChanged: boolean) {
    this.venue.Corporation.CorporationId = $event;
    if (this.venue.Corporation.CorporationId) {
      this.items = [];
      this.Modules = [];
      this.GetModuleList(this.venue.Corporation.CorporationId, isCorporationChanged);

      const selectedCorporation = this.Corporations.filter((corporation) => corporation.CorporationId == $event);
      if (selectedCorporation != null && selectedCorporation.length > 0) {
        this.venue.Subscription.SubscriptionId = selectedCorporation[0].Subscription.SubscriptionId;
        let serviceProviderId = selectedCorporation[0].ServiceProviderId;

        const CPServiceProvider = new ServiceProviderModule();
        CPServiceProvider.ServiceProviderCPId = this.authState.AESEncryptText(serviceProviderId);

        this.serviceprovidersService.GetSingle(CPServiceProvider).subscribe(
          (data) => {
            if (data.SPLicenseExpiryDate) {
              // this.serviceProviderExpiryDate = this.datePipe.transform(data.SPLicenseExpiryDate.split('T')[0], 'yyyy-MM-dd');
              this.serviceProviderExpiryDate = data.SPLicenseExpiryDate.split('T')[0];
              if (isCorporationChanged) {
                this.venue.Subscription.ExpiryDate = this.serviceProviderExpiryDate;
              }
            }
          },
          (error) => {
            console.log(
              'serviceproviderService GetServiceProviderById Call Failed. Status:' +
                error.status +
                ' Status Text:' +
                error.statusText,
            );
          },
        );
      }

      if (
        this.venue.Subscription != null &&
        this.venue.Subscription.SubscriptionId > 0 &&
        this.subscriptions != null &&
        this.subscriptions.length > 0
      ) {
        const obj = this.subscriptions.filter((s) => s.SubscriptionId == this.venue.Subscription.SubscriptionId);
        this.venue.Subscription.SubscriptionType = obj[0].SubscriptionType;
      }

      // this.getCorporationById(this.venue.Corporation.CorporationId);
    } else {
      this.items = [];
      this.Modules = [];
      this.subscriptions = [];
      this.venue.Modules = [];

      if (this.authState.getStoredSPLicenseExpiryDate()) {
        this.venue.Subscription.ExpiryDate = this.authState.getStoredSPLicenseExpiryDate();
      }
    }
  }

  public _handleReaderLoaded(_readerEvt: any) {
    // let binaryString = readerEvt.target.result;
  }

  public fileReset() {
    this.file = null;

    if (this.fileInputVariable != undefined) {
      this.fileInputVariable.nativeElement.value = '';
    }
    this.venue.VenueMapFiles = [];
  }

  public getAllPins() {
    this.venueService.GetAllPins().subscribe(
      (data) => {
        this.pins = data;
      },
      (error) => {
        console.log('venueService GetAllPins Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public getAllPriorities() {
    this.venueService.GetAllPriorities().subscribe(
      (data) => {
        this.priorities = data;
      },
      (error) => {
        console.log(
          'venueService GetAllPriorities Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllSeverities() {
    this.venueService.GetAllSeverities().subscribe(
      (data) => {
        this.severities = data;
      },
      (error) => {
        console.log(
          'venueService GetAllSeverities Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  public scrollTo(section) {
    document.querySelector('#' + section).scrollIntoView();
  }

  public getAllcountries(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.countryService.GetCountryDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.countries = data;
        if (!this.venue.VenueId) {
          const obj = this.countries.filter(
            (c) => c.CountryName == 'USA' || c.CountryName == 'United States Of America',
          );
          if (obj[0]) {
            this.venue.Country.CountryId = obj[0].CountryId;
            this.getAllstatesByCountry(this.venue.Country.CountryId);
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'countryService GetCountryDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public countryChange($event: any) {
    this.venue.Country.CountryId = $event;
    if (this.venue.Country.CountryId) {
      this.states = [];
      this.venue.State.StateId = null;
      this.getAllstatesByCountry(this.venue.Country.CountryId);

      this.cities = [];
      this.cityDisabled = true;
      this.venue.City.CityId = null;
    } else {
      this.states = [];
      this.stateDisabled = true;
      this.venue.State.StateId = null;

      this.cities = [];
      this.cityDisabled = true;
      this.venue.City.CityId = null;
    }
  }

  public getAllstatesByCountry(countryId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      country: { countryId },
    };

    if (countryId) {
      this.stateService.GetStateDropdown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.states = data;
          this.stateDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'stateService GetStateDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    } else {
      this.states = [];
      this.stateDisabled = true;
      this.loaderService.display(false);
    }
  }

  public stateChange($event: any) {
    this.venue.State.StateId = $event;
    if (this.venue.State.StateId) {
      this.cities = [];
      this.venue.City.CityId = null;
      this.getAllcitiesByState(this.venue.State.StateId);
    } else {
      this.cities = [];
      this.cityDisabled = true;
      this.venue.City.CityId = null;
    }
  }

  public getAllcitiesByState(stateId: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: this.searchTerm ? this.searchTerm.trim() : '',
      GenericSearch: 0,
      state: { stateId },
    };

    if (stateId) {
      this.cityService.GetCitiesDropDown(JSON.stringify(searchfilter), -1, -1).subscribe(
        (data) => {
          this.cities = data;
          this.cityDisabled = false;
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'cityService GetCitiesDropDown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    } else {
      this.cities = [];
      this.cityDisabled = true;
      this.loaderService.display(false);
    }
  }

  public checkUserAssignmentRule(value: any) {
    if (value == 1) {
      this.LocationUserAssignment = '';
      this.EitherOneOrThese = '';
      this.ShiftStaffSchedule = '1';
    } else if (value == 2) {
      this.ShiftStaffSchedule = '';
      this.EitherOneOrThese = '';
      this.LocationUserAssignment = '2';
    } else if (value == 3) {
      this.ShiftStaffSchedule = '';
      this.LocationUserAssignment = '';
      this.EitherOneOrThese = '3';
    }
  }

  public changeIsPassive(event: any) {
    this.venue.IsPassive = event.checked;
    if (this.venue.IsPassive == true) {
      this.isTime = true;
    } else {
      this.isTime = false;
      this.isScanTime = false;
      this.isCompletionTime = false;
      this.isSTime = false;
      this.isCTime = false;
      this.venue.MaxBeaconScanTime = null;
      this.venue.MaxCleaningAlertCompletionTime = null;
    }
  }

  public subscriptionType($event: any) {
    this.venue.Subscription.SubscriptionId = $event;
    if (this.venue.Subscription.SubscriptionId) {
      this.venue.Subscription.SubscriptionType = '';
      const obj = this.subscriptions.filter((u) => u.SubscriptionId == this.venue.Subscription.SubscriptionId);
      if (obj[0]) {
        this.venue.Subscription.SubscriptionType = obj[0].SubscriptionType;
        if (this.venue.Subscription.SubscriptionType == 'Basic') {
          this.IsPassive = true;
        } else if (
          this.venue.Subscription.SubscriptionType == null ||
          this.venue.Subscription.SubscriptionType != 'Basic'
        ) {
          this.IsPassive = false;
          this.isTime = false;
          this.isScanTime = false;
          this.isCompletionTime = false;
          this.isSTime = false;
          this.isCTime = false;
          this.venue.IsPassive = false;
          this.venue.MaxBeaconScanTime = null;
          this.venue.MaxCleaningAlertCompletionTime = null;
        }
        if (this.venue.Subscription.SubscriptionType == 'Enterprise') {
          this.map_for = true;
        } else {
          this.map_for = false;
        }
      }
    } else {
      this.IsPassive = false;
      this.isTime = false;
      this.isScanTime = false;
      this.isCompletionTime = false;
      this.isSTime = false;
      this.isCTime = false;
      this.venue.IsPassive = false;
      this.venue.MaxBeaconScanTime = null;
      this.venue.MaxCleaningAlertCompletionTime = null;

      this.map_for = false;
    }
  }

  public changeScanTime(event: any) {
    this.isScanTime = event.checked;
    if (this.isScanTime == true) {
      this.isSTime = true;
    } else {
      this.isSTime = false;
      this.venue.MaxBeaconScanTime = null;
    }
  }

  public changeCompletionTime(event: any) {
    this.isCompletionTime = event.checked;
    if (this.isCompletionTime == true) {
      this.isCTime = true;
    } else {
      this.isCTime = false;
      this.venue.MaxCleaningAlertCompletionTime = null;
    }
  }

  public getAllCMMSIntegrationtypes(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetCMMSIntegrationtypeDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.CmmsIntegrationTypes = data;
      },
      (error) => {
        console.log(
          'venueService getAllCMMSIntegrationtypes Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public changeCMMSIntegrationtype($event: any) {
    this.venue.CmmsIntegrationType.IntegrationTypeId = $event;
    if (this.venue.CmmsIntegrationType.IntegrationTypeId != null) {
      this.GetAllCMMSIntegrationTypeKeys(this.venue.CmmsIntegrationType.IntegrationTypeId);
    } else {
      this.venue.RelVenueCmmsIntegrationTypeUrls = [];
      this.venue.CmmsIntegrationType.IntegrationTypeId = null;
    }
  }

  public GetAllCMMSIntegrationTypeKeys(IntegrationTypeId: any) {
    if (IntegrationTypeId != null) {
      const CPintegrationtype = new CMMSIntegrationTypeKey();
      CPintegrationtype.IntegrationTypeCPId = this.authState.AESEncryptText(IntegrationTypeId);
      this.venueService.GetCMMSIntegrationTypeKeys(CPintegrationtype).subscribe(
        (data) => {
          if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].CmmsIntegrationType.CmmsIntegrationTypeKey.ShowEye != null) {
                data[i].CmmsIntegrationType.CmmsIntegrationTypeKey.isShowEye = data[i].CmmsIntegrationType
                  .CmmsIntegrationTypeKey.ShowEye
                  ? true
                  : false;
              }

              if (data[i].CmmsIntegrationType.CmmsIntegrationTypeKey.ControlDataType == 'CheckBox') {
                if (data[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IntegrationTypeKeyUrl == '1') {
                  data[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = 1;
                } else {
                  data[i].CmmsIntegrationType.CmmsIntegrationTypeKey.IsActive = 0;
                }
              }
            }
            this.venue.RelVenueCmmsIntegrationTypeUrls = data;
          } else {
            this.venue.RelVenueCmmsIntegrationTypeUrls = [];
          }
        },
        (error) => {
          console.log(
            'venueService GetCMMSIntegrationTypeKeys Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
        },
      );
    }
  }

  public GetModuleList(CorporationId: number, isCorporationChanged: boolean) {
    this.isCheckBoxVisible = false;
    this.loaderService.display(true);
    const CPcorporation = new CorporationModule();
    CPcorporation.CorporationCPId = this.authState.AESEncryptText(CorporationId);

    this.venueService.GetModueleList(CPcorporation).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.isCheckBoxVisible = true;
          let that = this;
          this.Modules = [];

          // add module
          if (this.venue.VenueId == null) {
            this.Modules = data;
            if (this.Modules != null && this.Modules.length > 0) {
              this.items = [];

              this.Modules.forEach(function (item) {
                let childrens = [];
                item.ModuleFeatures.forEach(function (moduleFeature) {
                  let ModuleFeatureChildrens = [];
                  childrens.push({
                    text: moduleFeature.ModuleFeatureName,
                    value: moduleFeature.ModuleFeatureId,
                    children: ModuleFeatureChildrens,
                    collapsed: true,
                  });

                  moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
                    let ModuleFeatureTypesChildrens = [];
                    ModuleFeatureChildrens.push({
                      text: moduleFeatureType.FeatureTypeName,
                      value: moduleFeatureType.FeatureTypeCode,
                      children: ModuleFeatureTypesChildrens,
                      collapsed: true,
                    });

                    moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                      featureConfig.IsActive = featureConfig.IsActive ? true : false;
                      ModuleFeatureTypesChildrens.push({
                        text: featureConfig.FeatureConfigurationName,
                        checked: featureConfig.IsActive,
                        value: featureConfig.FeatureConfigurationId,
                        collapsed: true,
                      });
                    });
                  });
                });
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    value: item.ModuleId,
                    checked: false,
                    children: childrens,
                    collapsed: true,
                  }),
                );
              });
            }
          }

          // update module
          if (this.venue.VenueId != null) {
            if (isCorporationChanged) {
              this.items = [];
              this.venue.Modules = [];
            }

            if (this.venue.Modules != null && this.venue.Modules.length > 0) {
              this.items = [];

              this.venue.Modules.forEach(function (item) {
                let childrens = [];
                item.ModuleFeatures.forEach(function (moduleFeature) {
                  let ModuleFeatureChildrens = [];
                  childrens.push({
                    text: moduleFeature.ModuleFeatureName,
                    value: moduleFeature.ModuleFeatureId,
                    children: ModuleFeatureChildrens,
                    // collapsed: true
                  });

                  moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
                    let ModuleFeatureTypesChildrens = [];
                    ModuleFeatureChildrens.push({
                      text: moduleFeatureType.FeatureTypeName,
                      value: moduleFeatureType.FeatureTypeCode,
                      children: ModuleFeatureTypesChildrens,
                      // collapsed: true
                    });

                    moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                      featureConfig.IsActive = featureConfig.IsActive ? true : false;
                      ModuleFeatureTypesChildrens.push({
                        text: featureConfig.FeatureConfigurationName,
                        checked: featureConfig.IsActive,
                        value: featureConfig.FeatureConfigurationId,
                        // collapsed: true
                      });
                    });
                  });
                });
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    value: item.ModuleId,
                    checked: false,
                    children: childrens,
                    // collapsed: true
                  }),
                );
              });

              this.featureConfigText = [];
              if (this.venue.Modules != null && this.venue.Modules.length > 0) {
                for (let i = 0; i < this.venue.Modules.length; i++) {
                  for (let j = 0; j < this.venue.Modules[i].ModuleFeatures.length; j++) {
                    for (let k = 0; k < this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes.length; k++) {
                      for (
                        let l = 0;
                        l < this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations.length;
                        l++
                      ) {
                        if (
                          this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                            .FeatureConfigurationDataType == 'textbox'
                        ) {
                          const fConfig = new FeatureConfiguration();
                          fConfig.FeatureConfigurationId =
                            this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[
                              l
                            ].FeatureConfigurationId;

                          fConfig.FeatureConfigurationName =
                            this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[
                              l
                            ].FeatureConfigurationName;

                          fConfig.FeatureConfigurationValue =
                            this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[
                              l
                            ].FeatureConfigurationValue;

                          fConfig.FeatureConfigurationCode =
                            this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[
                              l
                            ].FeatureConfigurationCode;

                          fConfig.IsActive =
                            this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[
                              l
                            ].IsActive;
                          this.featureConfigText.push(fConfig);
                        }
                      }
                    }
                  }
                }
              }
            } else {
              this.Modules = data;
              if (this.Modules != null && this.Modules.length > 0) {
                this.items = [];

                this.Modules.forEach(function (item) {
                  let childrens = [];
                  item.ModuleFeatures.forEach(function (moduleFeature) {
                    let ModuleFeatureChildrens = [];
                    childrens.push({
                      text: moduleFeature.ModuleFeatureName,
                      value: moduleFeature.ModuleFeatureId,
                      children: ModuleFeatureChildrens,
                      collapsed: true,
                    });

                    moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
                      let ModuleFeatureTypesChildrens = [];
                      ModuleFeatureChildrens.push({
                        text: moduleFeatureType.FeatureTypeName,
                        value: moduleFeatureType.FeatureTypeCode,
                        children: ModuleFeatureTypesChildrens,
                        collapsed: true,
                      });

                      moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                        featureConfig.IsActive = featureConfig.IsActive ? true : false;
                        ModuleFeatureTypesChildrens.push({
                          text: featureConfig.FeatureConfigurationName,
                          checked: featureConfig.IsActive,
                          value: featureConfig.FeatureConfigurationId,
                          collapsed: true,
                        });
                      });
                    });
                  });
                  that.items.push(
                    new TreeviewItem({
                      text: item.ModuleName,
                      value: item.ModuleId,
                      checked: false,
                      children: childrens,
                      collapsed: true,
                    }),
                  );
                });
              }
            }
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'venueService GetModueleList Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public onSelectedChange(_downlineItems: DownlineTreeviewItem[]) {
    if (_downlineItems.find((x) => x.item.text === 'Enable Flight Departure Alerts')) {
      this.isFlightDataEnabled = true;
    } else {
      this.isFlightDataEnabled = false;
    }
    this.isAllFeatureConfigurationChecked = false;

    // Add venue
    if (this.venue.VenueId == null) {
      if (_downlineItems && _downlineItems.length > 0) {
        if (this.featureConfigText && this.featureConfigText.length > 0) {
          let i = 0;
          let entry1;
          while (i < this.featureConfigText.length) {
            entry1 = this.featureConfigText[i];
            if (
              _downlineItems.some(function (entry2) {
                return entry1.FeatureConfigurationId === entry2.item.value;
              })
            ) {
              // Found, progress to next
              ++i;
            } else {
              // Not found, remove
              this.featureConfigText.splice(i, 1);
            }
          }
        }

        if (this.Modules != null && this.Modules.length > 0) {
          for (let i = 0; i < _downlineItems.length; i++) {
            for (let j = 0; j < this.Modules.length; j++) {
              for (let k = 0; k < this.Modules[j].ModuleFeatures.length; k++) {
                for (let l = 0; l < this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes.length; l++) {
                  for (
                    let m = 0;
                    m < this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations.length;
                    m++
                  ) {
                    if (
                      this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[m]
                        .FeatureConfigurationName == _downlineItems[i].item.text
                    ) {
                      let obj = this.featureConfigText.filter(
                        (ft) => ft.FeatureConfigurationName == _downlineItems[i].item.text,
                      );
                      if (obj && obj[0]) {
                        continue;
                      } else {
                        if (
                          this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[m]
                            .FeatureConfigurationDataType == 'textbox'
                        ) {
                          if (_downlineItems[i].item.checked == true) {
                            const fConfig = new FeatureConfiguration();
                            fConfig.FeatureConfigurationId = _downlineItems[i].item.value;
                            fConfig.FeatureConfigurationName = _downlineItems[i].item.text;
                            fConfig.FeatureConfigurationCode = this.getFeatureConfigurationCode(
                              _downlineItems[i].item.value,
                            );
                            fConfig.IsActive = _downlineItems[i].item.checked ? 1 : 0;
                            fConfig.FeatureConfigurationValue = '';
                            this.featureConfigText.push(fConfig);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        this.featureConfigText = [];
      }
    }

    // Update venue
    if (this.venue.VenueId != null) {
      if (_downlineItems && _downlineItems.length > 0) {
        if (this.featureConfigText && this.featureConfigText.length > 0) {
          let i = 0;
          let entry1;
          while (i < this.featureConfigText.length) {
            entry1 = this.featureConfigText[i];
            if (
              _downlineItems.some(function (entry2) {
                return entry1.FeatureConfigurationId === entry2.item.value;
              })
            ) {
              // Found, progress to next
              ++i;
            } else {
              // Not found, remove
              this.featureConfigText.splice(i, 1);
            }
          }
        }

        for (let i = 0; i < _downlineItems.length; i++) {
          let obj = this.checkConfigurationType(_downlineItems[i].item.value);
          if (obj == true) {
            let fConfigs = this.featureConfigText.filter(
              (c) => c.FeatureConfigurationId == _downlineItems[i].item.value,
            );
            if (fConfigs.length == 0) {
              const fConfig = new FeatureConfiguration();
              fConfig.FeatureConfigurationId = _downlineItems[i].item.value;
              fConfig.FeatureConfigurationName = _downlineItems[i].item.text;
              fConfig.FeatureConfigurationCode = this.getFeatureConfigurationCode(_downlineItems[i].item.value);
              fConfig.IsActive = _downlineItems[i].item.checked ? 1 : 0;
              fConfig.FeatureConfigurationValue = '';
              this.featureConfigText.push(fConfig);
            }
          }
        }
      } else {
        this.featureConfigText = [];
      }
    }

    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let item = this.treeviewComponent.items.filter((tree) => tree.checked == false || tree.checked == undefined);
      if (item && item.length > 0) {
        this.isAllFeatureConfigurationChecked = false;
      } else {
        this.isAllFeatureConfigurationChecked = true;
      }
    }
  }

  public onAllCheckedChange(event: any) {
    let that = this;
    this.treeviewComponent.items.forEach(function (it) {
      it.checked = event.checked;
      that.changeChildrenSelection(it, event.checked);
    });

    if (event.checked == true) {
      if (this.venue.VenueId == null) {
        this.featureConfigText = [];
        if (this.treeviewComponent.items[0].checked == true) {
          if (this.Modules != null && this.Modules.length > 0) {
            for (let j = 0; j < this.Modules.length; j++) {
              for (let k = 0; k < this.Modules[j].ModuleFeatures.length; k++) {
                for (let l = 0; l < this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes.length; l++) {
                  for (
                    let m = 0;
                    m < this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations.length;
                    m++
                  ) {
                    if (
                      this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[m]
                        .FeatureConfigurationDataType == 'textbox'
                    ) {
                      const fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationId;

                      fConfig.FeatureConfigurationName =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationName;

                      fConfig.IsActive = this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                        m
                      ].IsActive
                        ? 1
                        : 0;

                      fConfig.FeatureConfigurationValue =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationValue;

                      fConfig.FeatureConfigurationCode =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationCode;

                      this.featureConfigText.push(fConfig);
                    }
                  }
                }
              }
            }
          }
        } else if (this.treeviewComponent.items[0].checked == false) {
          this.featureConfigText = [];
        }
      }

      if (this.venue.VenueId != null) {
        this.featureConfigText = [];
        if (this.treeviewComponent.items[0].checked == true) {
          if (this.venue.Modules != null && this.venue.Modules.length > 0) {
            for (let j = 0; j < this.venue.Modules.length; j++) {
              for (let k = 0; k < this.venue.Modules[j].ModuleFeatures.length; k++) {
                for (let l = 0; l < this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes.length; l++) {
                  for (
                    let m = 0;
                    m < this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations.length;
                    m++
                  ) {
                    if (
                      this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[m]
                        .FeatureConfigurationDataType == 'textbox'
                    ) {
                      const fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId =
                        this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationId;

                      fConfig.FeatureConfigurationName =
                        this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationName;

                      fConfig.IsActive = this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l]
                        .FeatureConfigurations[m].IsActive
                        ? 1
                        : 0;

                      fConfig.FeatureConfigurationValue =
                        this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationValue;

                      fConfig.FeatureConfigurationCode =
                        this.venue.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationCode;

                      this.featureConfigText.push(fConfig);
                    }
                  }
                }
              }
            }
          } else if (this.Modules != null && this.Modules.length > 0) {
            for (let j = 0; j < this.Modules.length; j++) {
              for (let k = 0; k < this.Modules[j].ModuleFeatures.length; k++) {
                for (let l = 0; l < this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes.length; l++) {
                  for (
                    let m = 0;
                    m < this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations.length;
                    m++
                  ) {
                    if (
                      this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[m]
                        .FeatureConfigurationDataType == 'textbox'
                    ) {
                      const fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationId;

                      fConfig.FeatureConfigurationName =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationName;

                      fConfig.IsActive = this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                        m
                      ].IsActive
                        ? 1
                        : 0;

                      fConfig.FeatureConfigurationValue =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationValue;

                      fConfig.FeatureConfigurationCode =
                        this.Modules[j].ModuleFeatures[k].ModuleFeatureTypes[l].FeatureConfigurations[
                          m
                        ].FeatureConfigurationCode;

                      this.featureConfigText.push(fConfig);
                    }
                  }
                }
              }
            }
          }
        } else if (this.treeviewComponent.items[0].checked == false) {
          this.featureConfigText = [];
        }
      }
    } else {
      this.featureConfigText = [];
    }
  }

  public changeChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  public getVenueFeatureConfigurations(venueId: number) {
    this.loaderService.display(true);
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(venueId);
    this.venueService.GetVenueFeatureConfigurations(CPvenue).subscribe(
      (data) => {
        this.modules = data;
        if (this.modules != null && this.modules.length > 0) {
          this.configuration.venueFeaturesConfig = [];
          this.configuration.venueFeaturesConfig = this.modules;
        } else {
          this.configuration.venueFeaturesConfig = [];
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'venueService GetVenueFeatureConfigurations Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public checkConfigurationType(featureConfigId: number) {
    if (this.venue.Modules != null && this.venue.Modules.length > 0) {
      for (let i = 0; i < this.venue.Modules.length; i++) {
        for (let j = 0; j < this.venue.Modules[i].ModuleFeatures.length; j++) {
          for (let k = 0; k < this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes.length; k++) {
            for (
              let l = 0;
              l < this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations.length;
              l++
            ) {
              if (
                this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                  .FeatureConfigurationId == featureConfigId
              ) {
                if (
                  this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                    .FeatureConfigurationDataType == 'textbox'
                ) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
  }

  public getFeatureConfigurationCode(featureConfigId: number) {
    if (this.venue.Modules != null && this.venue.Modules.length > 0) {
      for (let i = 0; i < this.venue.Modules.length; i++) {
        for (let j = 0; j < this.venue.Modules[i].ModuleFeatures.length; j++) {
          for (let k = 0; k < this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes.length; k++) {
            for (
              let l = 0;
              l < this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations.length;
              l++
            ) {
              if (
                this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                  .FeatureConfigurationId == featureConfigId
              ) {
                return this.venue.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                  .FeatureConfigurationCode;
              }
            }
          }
        }
      }
    } else if (this.Modules != null && this.Modules.length > 0) {
      for (let i = 0; i < this.Modules.length; i++) {
        for (let j = 0; j < this.Modules[i].ModuleFeatures.length; j++) {
          for (let k = 0; k < this.Modules[i].ModuleFeatures[j].ModuleFeatureTypes.length; k++) {
            for (
              let l = 0;
              l < this.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations.length;
              l++
            ) {
              if (
                this.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                  .FeatureConfigurationId == featureConfigId
              ) {
                return this.Modules[i].ModuleFeatures[j].ModuleFeatureTypes[k].FeatureConfigurations[l]
                  .FeatureConfigurationCode;
              }
            }
          }
        }
      }
    }
  }

  logoFileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files[0]) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Venue', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const extention = event.target.files[0].name.split('.');
      const ext = extention[extention.length - 1].toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg') {
        if (event.target.files && event.target.files[0]) {
          const filesize = event.target.files[0].size / Math.pow(1024, 2);
          if (filesize > this.utilitiesService.fileUploadSize) {
            this.utilitiesService.mdmodal(
              'Venue',
              'Picture should not be more than ' + this.utilitiesService.fileUploadSize + ' MB.',
            );
            event.target.value = '';
            return;
          } else {
            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.logoFile = fileList[0];
              const reader = new FileReader();
              reader.onload = this._logoHandleReaderLoaded.bind(this);
              reader.readAsBinaryString(this.logoFile);
            }
          }
        }
      } else {
        this.utilitiesService.smmodal('Venue', 'Please upload image of type .jpg, .jpeg, .png, .svg.');
        event.target.value = '';
      }
    }
  }

  _logoHandleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.venueLogoSrc = 'data:image/jpeg;base64,' + btoa(binaryString); // Converting binary string data.
  }

  logoFileReset() {
    this.logoFileInputVariable.nativeElement.value = '';
    this.logoFile = null;
    this.venueLogoSrc = null;
  }

  public getMainLogo() {
    this.venueService.GetClientLogo().subscribe(
      (data) => {
        this.clientLogo = data;
        this.authState.storeLogo(this.clientLogo);
        this.logoService.changeLogo(this.clientLogo);
      },
      (error) => {
        console.log(
          'venueService GetClientLogo Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  keyPressCheck($event: any) {
    if (
      $event.key == 'e' ||
      $event.key == 'E' ||
      $event.key == '-' ||
      $event.key == '.' ||
      $event.key == '+' ||
      ($event.target.value === '' && $event.key == '0')
    ) {
      $event.preventDefault();
      return false;
    }

    if ($event.target.value.length > 4) {
      $event.preventDefault();
      return false;
    }
  }

  CheckBadgeValues($event: any) {
    if (
      ($event.key == 'e' || $event.key == 'E' || $event.key == '-' || $event.key == '0' || $event.key == '+') &&
      $event.target.value.length == 0
    ) {
      $event.preventDefault();
      return false;
    }
    if ($event.target.value.length > 2) {
      $event.preventDefault();
      return false;
    }
  }

  public getAllVenueTypes() {
    this.loaderService.display(true);
    const searchfilter = {};

    this.venueService.GetAllVenueTypes(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.venueTypes = data;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService GetAllVenueTypes Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public enableTouchlessFeedback($event: any) {
    if ($event.checked) {
      this.isTouchlessFeedbackEnable = true;
    } else {
      this.venue.EnableTouchlessFeedbackReason = 0;
      this.venue.EnableTouchlessFeedbackComment = 0;

      // this.venue.IsNotificationForNegativeFeedbackEnabled = 0;
      // this.venue.NumberOfNegativeFeedbacksForNotification = null;

      this.isTouchlessFeedbackEnable = false;
    }
  }

  public enableNegativeFeedbackNotification($event: any) {
    if (!$event.checked) {
      this.venue.NumberOfNegativeFeedbacksForNotification = null;
    }
  }

  public getAllSurveys() {
    this.surveyQueResponseConfigService.GetSurveyQuestionResponseType().subscribe(
      (data) => {
        this.surveyQuestionResponseTypes = data;
        for (let i = 0; i < this.surveyQuestionResponseTypes.length; i++) {
          if (this.surveyQuestionResponseTypes[i].SurveyQuestionResponseTypeName == 'NEUTRAL') {
            this.surveyQuestionResponseTypes[i].SurveyQuestionResponseTypeName = 'NONE';
          }
        }
      },
      (error) =>
        console.log(
          'surveyQueResponseConfigService GetSurveyQuestionResponseType Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        ),
    );
  }

  public checkSurveyScoring(value: any) {
    if (value == 1) {
      this.venue.ConsiderNonCompletedSurvey = 1;
      this.ConsiderNonCompletedSurveyNo = 1;
    } else {
      this.venue.ConsiderNonCompletedSurvey = 0;
      this.ConsiderNonCompletedSurveyNo = 0;
    }
  }
};
